import { useConstants } from "contexts/ConstantsContext/parent";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import {
  deepRemoveDuplicates,
  regexValidateEmail,
} from "../../../components/tools";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { useMutation } from "../../../tools/api";
import { PeopleManagementContext } from "../PeopleManagementProvider";
import { rolesDataDecider } from "./deciders";
import { memberFinder } from "./utils";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { eventsTracker } from "../../../universalFunctions/events";
import CancelEditImage from "../components/PeopleManagementModal/CancelEditImage";
import CancelCreateTeamImage from "../components/PeopleManagementModal/CancelCreateTeamImage";
import { useTranslation } from "react-i18next";

export const useTeamOptions = () => {
  const { teams } = useContext(PeopleManagementContext);

  return { options: teams };
};

export const useMemberSearchConditions = (value) => {
  const {
    users,
    cleanUsers,
    isInviteUser,
    selectedMembers,
    unassignedUsers,
    isAddManagerStep,
    requestedUsers,
  } = useContext(PeopleManagementContext);

  const sorter = (
    { name: prevName, email: prevEmail },
    { name: currName, email: currEmail }
  ) => {
    const prevIdentity = prevName || prevEmail || "";
    const currIdentity = currName || currEmail || "";

    if (prevIdentity > currIdentity) return 1;
    if (prevIdentity < currIdentity) return -1;
    return 0;
  };

  const newMembers = selectedMembers.filter(({ id }) => !id);

  const cleanUnassignedUsers = isAddManagerStep
    ? [...newMembers, ...users]
    : unassignedUsers.filter(
        ({ id: userId, name: userName, email: userEmail }) => {
          return !selectedMembers.find(
            ({
              id: unassignedUserId,
              name: unassignedUserName,
              email: unassignedUserEmail,
            }) =>
              userId === unassignedUserId ||
              userName === unassignedUserName ||
              userEmail === unassignedUserEmail
          );
        }
      );

  const searchValue = value.toLocaleLowerCase();

  const isSearching = !!searchValue;

  const foundMemberOnAnotherTeam = (isInviteUser ? users : cleanUsers).find(
    (member) => memberFinder({ member, searchValue })
  );
  const foundUnassignedMembers = cleanUnassignedUsers
    .filter((member) => memberFinder({ member, searchValue, isExact: false }))
    .sort(sorter);

  const isFoundMemberOnAnotherTeam = !!foundMemberOnAnotherTeam;
  const isFoundUnassignedMembers = foundUnassignedMembers.length > 0;

  const isAlreadySelected = !!selectedMembers.find((member) =>
    memberFinder({ member, searchValue })
  );

  const isAlreadyRequested = !!requestedUsers?.find((member) =>
    memberFinder({ member, searchValue, isEmailOnly: true })
  );

  const isValidEmail = regexValidateEmail(value);

  const isIncludedInUnassignedMembers = foundUnassignedMembers.find(
    ({ email }) => email.toLocaleLowerCase() === searchValue
  );

  const canIncludeNewMember =
    isValidEmail &&
    !isAlreadySelected &&
    !isFoundMemberOnAnotherTeam &&
    !isIncludedInUnassignedMembers;

  return {
    isSearching,
    isValidEmail,
    isAlreadySelected,
    isFoundUnassignedMembers,
    isFoundMemberOnAnotherTeam,
    canIncludeNewMember,
    foundUnassignedMembers: isAddManagerStep
      ? foundUnassignedMembers.filter(({ status }) => status !== "blocked")
      : foundUnassignedMembers,
    foundMemberOnAnotherTeam,
    isAlreadyRequested,
  };
};

export const useContentTranslate = () => {
  const {
    isTeamDetailStep,
    isAddMembersStep,
    isAddManagerStep,
    isConfirmationStep,
  } = useContext(PeopleManagementContext);

  if (isTeamDetailStep) return "0px 0px";
  if (isAddMembersStep) return "-516px 0px";
  if (isAddManagerStep) return "-1032px 0px";
  if (isConfirmationStep) return "-1548px 0px";
  return "0px 0px";
};

export const useForwardText = () => {
  const {
    isInviteUser,
    isDedicatedMode,
    isTeamDetailStep,
    isAddMembersStep,
    isAddManagerStep,
    isConfirmationStep,
  } = useContext(PeopleManagementContext);

  if (isDedicatedMode) {
    if (isInviteUser) return "Invite";
    if (isTeamDetailStep) return "Save Changes";
    if (isAddMembersStep) return "Add ";
    if (isAddManagerStep) return "Save Changes";
    return "";
  }

  if (isTeamDetailStep) return "Next";
  if (isAddMembersStep) return "Add";
  if (isAddManagerStep) return "Assign";
  if (isConfirmationStep) return "Create Team";
  return "";
};

export const useDisableForward = () => {
  const {
    teams,
    teamDetails,
    hasNewAdmins,
    isDedicatedMode,
    isTeamDetailStep,
    isAddMembersStep,
    isAddManagerStep,
    isAgreedAdminPolicy,
    hasNewAdminTeamManager,
    isDetectedAddManagerChanges,
    isDetectedTeamDetailsChanges,
  } = useContext(PeopleManagementContext);

  const isExistTeamName = teams.find(
    ({ name }) =>
      (name?.toLowerCase() || "") === (teamDetails?.name?.toLowerCase() || "")
  );

  const isItsOwnName =
    isDedicatedMode &&
    teamDetails.name === teams.find(({ id }) => id === teamDetails.id)?.name;

  const isEmptyName = !teamDetails.name;
  const isEmptyMembers = teamDetails?.members?.length === 0;

  if (isDedicatedMode) {
    if (isTeamDetailStep && isEmptyName) return true;
    if (isTeamDetailStep && !isDetectedTeamDetailsChanges) return true;
    if (isTeamDetailStep && isItsOwnName) return false;
    if (isTeamDetailStep && isExistTeamName) return true;
    if (isAddMembersStep && isEmptyMembers) return true;
    if (isAddMembersStep && hasNewAdmins && !isAgreedAdminPolicy) return true;
    if (isAddManagerStep && hasNewAdminTeamManager && !isAgreedAdminPolicy)
      return true;
    if (isAddManagerStep && isDetectedAddManagerChanges) return false;
    if (isAddManagerStep) return true;
    return false;
  }

  if (isExistTeamName) return true;
  if (isTeamDetailStep && isEmptyName) return true;
  if (isAddMembersStep && isEmptyMembers) return true;
  if (isAddMembersStep && hasNewAdmins && !isAgreedAdminPolicy) return true;
  if (isAddManagerStep && hasNewAdminTeamManager && !isAgreedAdminPolicy)
    return true;

  return false;
};

export const usePeopleTable = ({ isFromTeams }) => {
  const { query } = useRouter();
  const { t } = useTranslation("user-and-teams/index");
  const { users, teamDetail, isTeamDetailPage } = useContext(
    PeopleManagementContext
  );
  const teamId = Number(query.id);
  const teamUsers = users.filter((user) => user.team_id === teamId) || [];

  // const usersToUse = isEmpty(teamDetail) ? users : teamDetail.users;

  // all user
  const usersToUse = isFromTeams ? teamUsers : users || [];

  const tableTotalWidth = 1136;
  const tableWidth = (number) => `${(number / tableTotalWidth) * 100}%`;

  const headers = [
    {
      label: t("Member"),
      width: tableWidth(isTeamDetailPage ? 564 : 424),
      textAlign: "left",
    },
    !isTeamDetailPage && {
      label: t("Team"),
      width: tableWidth(140),
      textAlign: "left",
    },
    {
      label: t("Role"),
      width: tableWidth(148),
      textAlign: "left",
    },
    {
      label: t("Phone Number"),
      width: tableWidth(148),
      textAlign: "left",
    },
    {
      label: t("Status"),
      width: tableWidth(140),
      textAlign: "left",
    },
    {
      label: "",
      width: tableWidth(40),
    },
    {
      label: "",
      width: tableWidth(40),
    },
    {
      label: "",
      width: tableWidth(40),
    },
  ].filter((header) => header);

  return { headers, users: usersToUse };
};

export const useRoleOptions = ({ defaultValue = {} }) => {
  const { allRoles, usersRaw } = useContext(PeopleManagementContext);

  const rolesData = rolesDataDecider({
    users: usersRaw,
    defaultValue,
    rolesData: allRoles,
  });

  const label = (allRoles ?? []).find(
    ({ name }) => name === defaultValue.name
  )?.label;

  const cleanDefaultValue = {
    label,
    value: defaultValue.id,
  };

  return { options: rolesData, defaultValue: cleanDefaultValue };
};

export const useOffsetRoleDropdown = () => {
  const {
    isInviteUser,
    isDedicatedMode,
    selectedMembers,
    isAddMembersStep,
    isAddManagerStep,
    selectedMemberRefs,
    selectingMemberRole,
    memberListScrollPosition,
  } = useContext(PeopleManagementContext);

  const selectedMemberCurrents = selectedMemberRefs.map(
    ({ current }) => current
  );

  const cleanSelectedMemberCurrents = deepRemoveDuplicates(
    selectedMemberCurrents,
    "offsetTop"
  );

  const selectedUserIndex = selectedMembers.findIndex(
    ({ email }) => selectingMemberRole.email === email
  );

  const indexToUse = isAddManagerStep ? 0 : selectedUserIndex;

  const memberCurrent = cleanSelectedMemberCurrents[indexToUse];

  const baseOffsetTopDecider = () => {
    if (isDedicatedMode) {
      if (isInviteUser) return 312;
      if (isAddManagerStep) return 418;
      if (isAddMembersStep) return 360;
      return 376;
    }

    if (isAddManagerStep) return 490;
    return 448;
  };

  const baseOffsetTop = baseOffsetTopDecider();
  const memberOffsetTop = memberCurrent?.offsetTop ?? 0;
  const scrollDeductor = isAddManagerStep ? 0 : memberListScrollPosition;

  const offsetRoleDropdown = baseOffsetTop + memberOffsetTop - scrollDeductor;

  const pixelValue = `${offsetRoleDropdown}px`;

  return pixelValue;
};

export const useSelectRole = () => {
  const {
    users,
    roles,
    isAddManagerStep,
    selectingMemberRole,
    setTeamDetails,
    setSelectingMemberRole,
  } = useContext(PeopleManagementContext);

  const handleSelectRole = (roleId) => {
    setTeamDetails((prev) => {
      const prevCopy = { ...prev };
      const prevTeamMembers = prevCopy.members;
      const prevTeamManager = prevCopy.manager;

      const editedMemberIndex = prevTeamMembers.findIndex(
        ({ email }) => email === selectingMemberRole.email
      );

      const assignedRole = roles.find(({ id }) => id === roleId);

      if (isAddManagerStep) {
        prevTeamManager.roles = [
          assignedRole,
          ...prevTeamManager.roles.slice(1, prevTeamManager.roles.length),
        ];
      } else {
        prevTeamMembers[editedMemberIndex].roles = [
          assignedRole,
          ...prevTeamMembers[editedMemberIndex].roles.slice(
            1,
            prevTeamMembers[editedMemberIndex].roles.length
          ),
        ];
      }

      const isAssignedAsManager = editedMemberIndex >= 0;
      const isAssignedAsMember =
        selectingMemberRole.email === prevTeamManager.email;

      if (isAddManagerStep && isAssignedAsManager) {
        prevTeamMembers[editedMemberIndex].roles[0] = assignedRole;
      } else if (isAssignedAsMember) {
        prevTeamManager.roles[0] = assignedRole;
      }

      if (isAddManagerStep) eventsTracker("change_manager_role");
      else eventsTracker("change_member_role");

      return { ...prevCopy };
    });

    setSelectingMemberRole({});
  };

  return handleSelectRole;
};

export const useSelectMember = () => {
  const { selectedMembers, isAddManagerStep, teamDetails, setTeamDetails } =
    useContext(PeopleManagementContext);

  const handleSelectMember = ({
    withClose = false,
    member = {},
    resetField = () => {},
  }) => {
    setTeamDetails((prev) => {
      const prevCopy = { ...prev };

      const roles = member.roles.map((role) => ({ ...role }));

      const selectedMember = {
        ...member,
        roles,
      };

      if (isAddManagerStep) {
        const foundIndex = selectedMembers.findIndex(
          ({ email }) => email === selectedMember.email
        );

        const isAlsoAssignedAsMember = foundIndex >= 0;

        if (isAlsoAssignedAsMember)
          selectedMember.roles = selectedMembers[foundIndex].roles;

        eventsTracker("add_manager");

        return {
          ...prevCopy,
          manager: selectedMember,
        };
      }

      const isAlsoAssignedAsManager =
        selectedMember.email === teamDetails?.manager?.email;

      if (isAlsoAssignedAsManager)
        selectedMember.roles = teamDetails?.manager?.roles;

      eventsTracker("add_member");

      return {
        ...prevCopy,
        members: [selectedMember, ...prev.members],
      };
    });

    resetField({ withClose });
  };

  return handleSelectMember;
};

export const useMutateTeam = () => {
  const { locale } = useRouter();
  const isId = locale === "id";
  const { t } = useTranslation("user-and-teams/users");
  const { t: tTeam } = useTranslation("user-and-teams/teams");
  const [stopSideEffect, setStopSideEffect] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const [PIN, setPIN] = useState(null);
  const {
    roles,
    dedicate,
    teamDetails,
    changesReference,
    closeModal,
    refetchPeopleData,
  } = useContext(PeopleManagementContext);

  const { addUsersWithTeam, addTeamWithUsers, addUsers } = useConstants();

  const { successSnackBar, errorSnackBar } = ToasterHook();

  const afterFinish = () => {
    setTeamId(null);
    closeModal();
    refetchPeopleData();
  };

  const { mutation: removeManager, loading: loadingRemoveManager } =
    useMutation({
      url: `/teams/${teamId}/remove_manager`,
      method: "delete",
      withError: false,
      afterSuccess: () => {
        afterFinish();
        successSnackBar({ msg: "Team manager has been removed!" });
      },
      handleError: () => {
        afterFinish();
      },
      type: "long",
    });

  const { mutation: assignManager, loading: loadingAssignManager } =
    useMutation({
      url: `/teams/${teamId}/assign_manager`,
      method: "put",
      withError: false,
      afterSuccess: (res) => {
        const isNewUserManager = Boolean(res?.data?.user);
        const isAddManager = dedicate === "add_manager";
        if (isNewUserManager) {
          addUsers({ newUsers: [res?.data?.user] });
        }
        if (isAddManager)
          successSnackBar({ msg: tTeam("Team manager has been updated!") });
        afterFinish();
      },
      handleError: () => {
        errorSnackBar({
          msg: (
            <>
              <span style={{ fontFamily: "GothamMedium" }}>
                {teamDetails?.manager?.email}
              </span>{" "}
              {t(" is under another company and can't be invited.")}
            </>
          ),
        });
        afterFinish();
      },
      type: "long",
    });

  const { mutation: createTeam, loading: loadingCreateTeam } = useMutation({
    url: "/teams",
    method: "post",
    afterSuccess: (res) => {
      const failedInvitees = res?.data?.failed;
      const successInvitees = res?.data?.success;
      const { id: createdTeamId, name: createdTeamName } = res?.data?.data;
      setTeamId(createdTeamId);

      const hasFailedInvitees = failedInvitees.length > 0;
      const hasSuccessInvitees = successInvitees.length > 0;

      if (hasSuccessInvitees) {
        addTeamWithUsers({
          newTeam: res?.data?.data,
          newUsers: successInvitees,
        });
      }

      if (hasFailedInvitees) {
        failedInvitees.forEach(({ email }) =>
          errorSnackBar({
            msg: (
              <>
                <span style={{ fontFamily: "GothamMedium" }}>{email}</span>
                {t(" is under another company and can't be invited.")}
              </>
            ),
          })
        );
      }

      setTimeout(() => {
        successSnackBar({
          msg: `${isId ? "Tim" : ""} ${createdTeamName} ${t(
            "team has been created!"
          )}`,
        });
      }, 500);

      setStopSideEffect(false);
    },
    type: "long",
  });

  const { mutation: editTeam, loading: loadingEditTeam } = useMutation({
    url: `/teams/${teamDetails.id}`,
    method: "put",
    afterSuccess: (res) => {
      const failedInvitees = res?.data?.failed;
      const successInvitees = res?.data?.success;
      const { id: editedTeamId } = res?.data?.data;
      setTeamId(editedTeamId);

      const hasFailedInvitees = failedInvitees.length > 0;
      const hasSuccessInvitees = successInvitees.length > 0;

      const isTeamSpecifications = dedicate === "team_specifications";
      const isAddMembers = dedicate === "add_members";
      const isAddManager = dedicate === "add_manager";

      const msgDecider = () => {
        if (isTeamSpecifications)
          return tTeam("Team details has been updated!");
        if (isAddMembers) return tTeam("New member(s) have been added!");
        return "";
      };

      const msg = msgDecider();

      const hideSuccessSnackbar =
        isAddManager || (isAddMembers && !hasSuccessInvitees);

      if (hasSuccessInvitees) {
        addUsersWithTeam({
          teamId: teamDetails?.id,
          newUsers: successInvitees,
        });
      }

      if (hasFailedInvitees) {
        failedInvitees.forEach(({ email }) =>
          errorSnackBar({
            msg: (
              <>
                <span style={{ fontFamily: "GothamMedium" }}>{email}</span> is
                under another company and can't be invited.
              </>
            ),
          })
        );
      }

      if (!hideSuccessSnackbar) {
        setTimeout(() => {
          successSnackBar({ msg });
        }, 500);
      }

      setStopSideEffect(false);
    },
    type: "long",
  });

  const mainRoleFinder = (manager) => {
    return (
      manager?.roles?.find(({ name }) => name.includes("partner"))?.name ?? ""
    );
  };

  useEffect(() => {
    const mayAssignManager =
      teamId && !stopSideEffect && !loadingAssignManager && Boolean(PIN);

    if (mayAssignManager) {
      const hasManager = !isEmpty(teamDetails.manager);
      const hasPreviousManager = !isEmpty(changesReference.manager);

      const managerId = teamDetails.manager?.id;
      const managerEmail = teamDetails.manager?.email;
      const managerRole = mainRoleFinder(teamDetails.manager);

      const previousManagerId = changesReference.manager?.id;
      const previousManagerRole = mainRoleFinder(changesReference.manager);

      const isTheSameManager = managerId === previousManagerId;
      const isTheSameRole = managerRole === previousManagerRole;

      if (hasPreviousManager && isTheSameManager && isTheSameRole) {
        afterFinish();
        setStopSideEffect(true);
        return;
      }

      if (!hasManager) {
        if (hasPreviousManager) removeManager({ data: { pin: PIN } });
        else afterFinish();
        setStopSideEffect(true);
        return;
      }

      const managerRoles =
        teamDetails.manager.roles ??
        roles.filter(({ name }) => name === "partner_maker");

      const roleIds = managerRoles.map(({ id }) => id);

      const payload = { manager: { role_ids: roleIds }, pin: PIN };

      if (managerId) payload.manager.id = managerId;
      else payload.manager.email = managerEmail;

      assignManager(payload);
      setStopSideEffect(true);
      return;
    }
  }, [
    teamId,
    teamDetails,
    stopSideEffect,
    changesReference,
    loadingAssignManager,
  ]);

  const payload = {
    name: teamDetails.name,
    color: teamDetails.color,
    members: (teamDetails.members || []).map(({ id, email, roles }) => {
      const roleIds = (roles || []).map(({ id }) => id);

      if (id) return { id, role_ids: roleIds };
      return { email, role_ids: roleIds };
    }),
  };

  const handleCreateTeam = (pin) => {
    setPIN(pin);
    createTeam({ ...payload, pin });
  };

  const handleEditTeam = (pin) => {
    setPIN(pin);
    editTeam({ ...payload, pin });
  };

  const loading =
    loadingEditTeam ||
    loadingCreateTeam ||
    loadingRemoveManager ||
    loadingAssignManager;

  return {
    payload,
    loading,
    editTeam: handleEditTeam,
    createTeam: handleCreateTeam,
  };
};

export const useEditUser = ({ user = {}, afterSuccess = () => {} }) => {
  const { t } = useTranslation("user-and-teams/users");
  const { changeUserData } = useConstants();

  const { successSnackBar } = ToasterHook();

  const { mutation: editUser, loading: loadingEditUser } = useMutation({
    url: `/business_users/${user.id}`,
    afterSuccess: (res) => {
      const updatedUserData = res?.data?.data ?? {};
      afterSuccess();
      changeUserData({ id: user?.id, userData: updatedUserData });
      successSnackBar({ msg: t("User details have been updated!") });
    },
  });

  const handleSubmit = (data = {}) => {
    const userRoleIds = user.roles
      .filter(
        ({ name, is_backoffice_role }) =>
          !name.includes("partner") && !is_backoffice_role
      )
      .map(({ id }) => id);

    const payload = {
      name: data.name ?? "",
      pin: data?.pin,
    };

    const roleIds = [...userRoleIds];

    const teamId = data?.team?.id;
    const roleId = data?.role?.value;
    const notes = data?.notes;

    if (teamId) payload.team_id = teamId;
    if (roleId) {
      payload.role_ids = [roleId, ...roleIds];
      payload.agreed = true;
    }
    if (notes) payload.notes = notes;

    eventsTracker("edit_user", payload);
    editUser(payload);
  };

  return { editUser: handleSubmit, loadingEditUser };
};

export const useInviteUser = () => {
  const { t } = useTranslation("user-and-teams/users");
  const { teamDetails, closeModal } = useContext(PeopleManagementContext);

  const { addUsers } = useConstants();

  const { successSnackBar, errorSnackBar, errorToasterApi } = ToasterHook();

  const { mutation: bulkInvite, loading: loadingBulkInvite } = useMutation({
    url: "/business_users/bulk_invite",
    method: "post",
    afterSuccess: (res) => {
      const isSuspicious = isEmpty(res?.data?.data);

      if (isSuspicious) {
        closeModal();
        return errorSnackBar({
          msg: t("Error! Please contact Jack team."),
          showClose: true,
        });
      }

      const failedInvitees = res?.data?.failed_invitation;
      const successInvitees = res?.data?.data;

      const hasFailedInvitees = failedInvitees?.length > 0;
      const hasSuccessInvitees = successInvitees?.length > 0;

      if (hasFailedInvitees) {
        failedInvitees?.forEach((email) =>
          errorSnackBar({
            msg: (
              <>
                <span style={{ fontFamily: "GothamMedium" }}>{email}</span>
                {t(" is under another company and can't be invited.")}
              </>
            ),
          })
        );
      }

      closeModal();
      addUsers({ newUsers: successInvitees });
      if (hasSuccessInvitees) {
        setTimeout(() => {
          successSnackBar({ msg: t("Invitation has been sent!") });
        }, 500);
      }
    },
    handleError: errorToasterApi,
    type: "long",
  });

  const handleInviteUser = (pin) => {
    const invitees = teamDetails.members.map((member) => {
      const invitee = {
        email: member.email,
        role_ids: member.roles.map(({ id }) => id),
      };

      delete invitee.roles;

      return invitee;
    });

    const payload = {
      users: invitees,
      pin,
    };

    bulkInvite(payload);
  };

  return { bulkInvite: handleInviteUser, loadingBulkInvite };
};

export const useFilters = () => {
  const { t } = useTranslation("users-and-teams/index");
  const { teams, loadingTeams, isTeamDetailPage } = useContext(
    PeopleManagementContext
  );

  const baseActiveFilters = ["role", "status"];

  const activeFilters = isTeamDetailPage
    ? baseActiveFilters
    : ["team", ...baseActiveFilters];

  const filters = [
    !isTeamDetailPage && {
      icon: "people",
      label: t("Team"),
      name: "team",
      search: true,
      type: "status",
      loading: loadingTeams,
      array: [...teams],
    },
    {
      icon: "category",
      label: t("Role"),
      name: "role",
      type: "status",
      array: [
        {
          label: "Business Owner",
          value: "partner_super_admin",
        },
        {
          label: "Admin",
          value: "partner_admin",
        },
        {
          label: "Employee",
          value: "partner_maker",
        },
        {
          label: "Bookkeeper",
          value: "partner_viewer",
        },
      ],
    },
    {
      icon: "loader-outline",
      label: t("Status"),
      name: "status",
      type: "status",
      array: [
        {
          label: t("Active"),
          value: "active",
        },
        {
          label: t("Deactivated"),
          value: "blocked",
        },
        {
          label: t("Invited"),
          value: "invited",
        },
        {
          label: t("Waiting Seat"),
          value: "pending",
        },
      ],
    },
  ].filter((filter) => filter);

  return { activeFilters, filters };
};

export const usePeopleManagementAccess = () => {
  const { user, userLoading } = useGetAuth();
  const { isManagingTeams, isAdminOrSuperAdmin, isEmployee } = getUserRole();

  const { push } = useRouter();

  const isAllowed =
    isManagingTeams ||
    isAdminOrSuperAdmin ||
    isEmployee ||
    isEmpty(user) ||
    userLoading;

  // useEffect(() => {
  //   if (isAllowed) return;

  //   push("/dashboard");
  // }, [isManagingTeams, isAdminOrSuperAdmin]);

  return { isAllowed };
};

export const useTeamDetailAccess = () => {
  const { teamDetail } = useContext(PeopleManagementContext);

  const { user, userLoading } = useGetAuth();

  const { isWeakManager, managedTeams, isAdminOrSuperAdmin } = getUserRole();

  const { push } = useRouter();

  const teamId = teamDetail.id;

  const managedTeamIds = (managedTeams ?? []).map(({ id }) => id);

  const isManagedTeam =
    (isWeakManager && managedTeamIds.includes(teamId)) || isAdminOrSuperAdmin;

  const isAllowed = isManagedTeam || isEmpty(user) || userLoading;

  // useEffect(() => {
  //   if (isAllowed) return;
  //   push("/people-management");
  // }, [isAllowed]);

  return { isManagedTeam };
};

export const useCancelConfirmationTexts = () => {
  const { t } = useTranslation("user-and-teams/teams");
  const {
    isDetectedAddManagerChanges,
    isDetectedAddMembersChanges,
    isDetectedCreateTeamChanges,
    isDetectedInviteUserChanges,
    isDetectedTeamDetailsChanges,
  } = useContext(PeopleManagementContext);

  const texts = {
    title: "",
    text: t("The changes you’ve made will not be saved."),
    buttonTextRight: t("Cancel & Delete"),
    buttonTextLeft: t("Continue Editing"),
    image: null,
  };

  if (isDetectedInviteUserChanges) {
    texts.title = t("Are you sure to cancel?");
    texts.text = t("All user(s) you’ve listed will not be invited.");
    texts.buttonTextLeft = t("Continue inviting");
    texts.buttonTextRight = t("Yes, cancel ");
    texts.image = <CancelEditImage />;
    return texts;
  }

  if (isDetectedTeamDetailsChanges) {
    texts.title = t("Are you sure to cancel editing?");
    texts.text = t("You'll lose all the changes you've made to this team.");
    texts.buttonTextLeft = t("Back to edit");
    texts.buttonTextRight = t("Yes, cancel");
    texts.image = <CancelEditImage />;
    return texts;
  }

  if (isDetectedAddMembersChanges) {
    texts.title = t("Are you sure to cancel?");
    texts.text = t("All user(s) you've listed will not be added to the team.");
    texts.buttonTextLeft = t("Continue adding");
    texts.buttonTextRight = t("Yes, cancel");
    texts.image = <CancelEditImage />;
    return texts;
  }

  if (isDetectedAddManagerChanges) {
    texts.title = t("Are you sure to cancel editing? ");
    texts.text = t("You will lose all the changes you've made to the manager.");
    texts.buttonTextLeft = t("Back to edit");
    texts.buttonTextRight = t("Yes, cancel");
    texts.image = <CancelEditImage />;
    return texts;
  }

  if (isDetectedCreateTeamChanges) {
    texts.title = t("Discard new team?");
    texts.text = t("You will lose all the progress you’ve made.");
    texts.buttonTextLeft = t("Cancel ");
    texts.buttonTextRight = t("Discard");
    texts.image = <CancelCreateTeamImage />;
    return texts;
  }

  return texts;
};
