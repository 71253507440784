import { Modal } from "reactstrap";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { jackColors } from "../../../assets/colors";
import styles from "./SimpleCenteredModal.module.css";
import { ReactNode } from "react";
import { ButtonJack } from "components/ButtonsJack/parent";
import { ModalCloseButtonProps, SimpleCenteredModalProps } from "./index.types";

const SimpleCenteredModal = ({
  isOpen,
  woClose = false,
  isHidden = false,
  isLoading = false,
  isDisabled = false,
  woLineDividers = false,
  children = null,
  customButtons = null,
  modalClassName = "",
  leftHeaderComponent = null,
  buttonText,
  toggle,
  onClick,
  customStyle = {},
  woButtons = false,
  ...props
}: SimpleCenteredModalProps) => {
  // if (!isOpen) return;
  const contentClassName = modalClassName ?? styles["simple-centered-modal"];

  const buttons = customButtons ?? (
    <ButtonJack
      disabled={isDisabled}
      onClick={onClick}
      isLoading={isLoading}
      style={{ width: "100%" }}
    >
      {buttonText || "Okay"}
    </ButtonJack>
  );

  return (
    <Modal
      style={customStyle}
      centered
      hidden={isHidden}
      isOpen={isOpen}
      contentClassName={contentClassName}
      toggle={toggle}
      {...props}
    >
      <div
        className={styles["modal-header"]}
        style={{
          borderBottom: woLineDividers
            ? "none"
            : `1px solid ${jackColors.neutral500}`,
        }}
      >
        {leftHeaderComponent || <div />}
        {!woClose && <ModalCloseButton onClick={toggle} />}
      </div>
      {children}
      {!woButtons && (
        <div
          className={styles["modal-footer"]}
          style={{
            borderTop: woLineDividers
              ? "none"
              : `1px solid ${jackColors.neutral500}`,
          }}
        >
          {buttons}
        </div>
      )}
    </Modal>
  );
};

export const ModalCloseButton = ({ onClick }: ModalCloseButtonProps) => (
  <button className={styles["close-btn-container"]} onClick={onClick}>
    <JackIcons name="close-outline" fill={jackColors.neutral900} />
  </button>
);

export default SimpleCenteredModal;
