import {
  GothamLight,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import bcaLogo from "../../../assets/images/bca-logo.svg";
import permataLogo from "../../../assets/images/permata-logo.svg";
import briLogo from "../../../assets/images/bri-logo.svg";
import mandiriLogo from "../../../assets/images/mandiri-logo.svg";
import cimbLogo from "../../../assets/images/cimb-logo.svg";
import { useModalHook } from "../../../components/Modals";
import { useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { containsOnlyDigits, useDebounce } from "../../../components/tools";
import { useHeader, useLayout } from "../../../contexts/Layout/parent";
import { ProgressBarJack } from "../../../components/Stepper";
import validateGif from "../../../assets/images/validating-local.gif";
import { add, parse, parseISO, toDate } from "date-fns";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useTranslation } from "react-i18next";
import { useLanguage } from "public/locales/translationFunctions";

export const font10 = {
  fontSize: 10,
  lineHeight: "12px",
};

export const font12 = {
  fontSize: 12,
  lineHeight: "16px",
};

export const font14 = {
  fontSize: 14,
  lineHeight: "20px",
};

export const font16 = {
  fontSize: 16,
  lineHeight: "24px",
};

export const font20 = {
  fontSize: 20,
  lineHeight: "24px",
};

export const font24 = {
  fontSize: 24,
  lineHeight: "32px",
};

export const icon12 = {
  width: 12,
  height: 12,
};

export const icon14 = {
  width: 14,
  height: 14,
};

export const icon16 = {
  width: 16,
  height: 16,
};

export const icon20 = {
  width: 20,
  height: 20,
};

export const icon24 = {
  width: 24,
  height: 24,
};

export const textEllipsis = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const findDuplicatesKeyValue = (array, key) => {
  const keyCounts = {};
  const checkedObjs = [];
  const duplicatedArray = [];

  for (const obj of array) {
    const objKey = obj[key];

    if (keyCounts[objKey]) {
      duplicatedArray.push(obj);
    }
    keyCounts[objKey] += 1;
    checkedObjs.push(keyCounts[objKey]);
  }

  return duplicatedArray;
};

export const VACheckerValidation = ({ data: dataRaw }) => {
  let counter = 0;

  for (const el of dataRaw) {
    const isValidUsageLimit = containsOnlyDigits(el["usage_limit"]);
    const isValidAmount = containsOnlyDigits(el["amount"]);
    if (!isValidUsageLimit || !isValidAmount) {
      counter += 1;
    }
  }

  const isNotPass = counter > 0;
  return { isNotPass };
};

export const bankNameMask = (bankName = "") => {
  return bankName?.split(" ")[1]?.toLowerCase();
};

export const iconCustom = (px) => {
  return {
    width: px,
    height: px,
  };
};

export const TextWithIcon = ({
  type = "regular",
  text,
  divStyle,
  textStyle,
  topIcon,
  bottomIcon,
  onClick = () => {},
}) => {
  const regularFont = type === "regular";
  const mediumFont = type === "medium";
  const lightFont = type === "light";
  return (
    <div style={{ ...divStyle, display: "flex" }} onClick={onClick}>
      {topIcon}
      {regularFont && <GothamRegular style={textStyle}>{text}</GothamRegular>}
      {mediumFont && <GothamMedium style={textStyle}>{text}</GothamMedium>}
      {lightFont && <GothamLight style={textStyle}>{text}</GothamLight>}
      {bottomIcon}
    </div>
  );
};

export const LogoBankVA = ({ bank }) => {
  const isBcaOrBri = bank === "bca" || bank === "bri";
  const size = isBcaOrBri ? 16 : 20;
  const bankLogo = {
    bca: bcaLogo,
    permata: permataLogo,
    bri: briLogo,
    mandiri: mandiriLogo,
    cimb: cimbLogo,
  };

  const logoSrc = bankLogo[bank];

  return <img src={logoSrc} style={{ width: 20, height: size }} />;
};

export const convertDateFromMinutes = (minutes) => {
  if (minutes === -1) return "";
  const now = new Date();
  const date = add(now, { minutes });
  return date;
};

export const statusMask = (status) => {
  const statusLC = status?.toLowerCase() || status;
  const isExpired = statusLC === "expired" || statusLC === "complete";
  const isNeedUpdate =
    statusLC === "limit_reached" || statusLC === "static_trx_expired";
  const isDeactivated = statusLC === "archived";
  const isActive =
    statusLC === "waiting_payment" || statusLC === "payment_detected";

  if (isExpired) return "Expired";
  if (isNeedUpdate) return "Need update";
  if (isDeactivated) return "Deactivated";
  if (isActive) return "Active";
  return status;
};

export const getLastFourDigits = (vaNumber) => vaNumber?.slice(-4);
export const UnavailableTextVACollection = (vaNumber) => {
  const { isBahasa } = useLanguage();
  const lastFour = getLastFourDigits(vaNumber);
  return isBahasa
    ? `**${lastFour} (Tidak Tersedia)`
    : `**${lastFour} (Currently Unavailable)`;
};

export const vaFormatter = (item, menu = "index") => {
  const {
    id,
    amount,
    bank,
    number,
    reference_id,
    username_display,
    status,
    is_lifetime,
    is_open,
    expiration_date,
    trx_expiration_date,
    usage_limit,
    transactions,
    fee,
    // v2
    va_number,
    expiration_time,
    va_status,
    partner_user_id,
    bank_code,
    created_at,
    settlement_status,
    description,
    code,
  } = item;

  const { vaBankOptions } = useConstants();

  const isAPI = menu === "api";
  const isTrxPage = menu === "api-trx";

  const isBRI = code === "002" || bank_code === "002";
  const isCurrentlyUnavailable = isBRI;

  const vaExpDate = isAPI
    ? convertDateFromMinutes(expiration_time)
    : expiration_date;

  const expiryDate = is_lifetime ? "never" : vaExpDate;

  const apiBankName = vaBankOptions.find(
    ({ code }) => code === bank_code
  )?.value;

  const vaNumberRaw = isAPI ? va_number : number;
  const isCurrentlyUnavailableText = UnavailableTextVACollection(vaNumberRaw);

  if (isTrxPage)
    return {
      created_at,
      displayName: va_number?.username_display,
      status: settlement_status?.toLowerCase(),
      amount,
    };

  return {
    id,
    closedTypeAmount: amount,
    expiryDate,
    trxExpirationDate: trx_expiration_date,
    referenceId: isAPI ? partner_user_id : reference_id,
    displayName: username_display,
    vaNumber: isCurrentlyUnavailable
      ? isCurrentlyUnavailableText
      : isAPI
      ? va_number
      : number,
    bankName: isAPI ? apiBankName : bank,
    status: statusMask(isAPI ? va_status : status),
    actualStatus: status,
    isOpen: is_open,
    usageLimit: usage_limit,
    transactions,
    adminFee: fee,
    created_at,
    description,
    isCurrentlyUnavailable,
  };
};

export const MenuDropdownLayout = ({
  clickComponent,
  content,
  contentDivStyle,
  moreDropdownStyle,
  onClick,
  component = (onClickWithToggle) => null,
}) => {
  //to make sure document is defined
  if (typeof window === "undefined") return null;

  const { isOpen, toggle, close } = useModalHook();
  const ref = useRef();
  useClickOutside({ ref, clickOutside: close });

  const [clientHeight, setClientHeight] = useState(0);

  const handleClick = (value) => {
    onClick(value);
    close();
  };

  const padding = 8;
  const modalHeight = "fit-content";

  const { clientWidth = 0, clientHeight: clientHeightButton = 0 } =
    ref?.current || {};

  const { scrollTop, scrollLeft } = useLayout();

  useEffect(close, [scrollTop, scrollLeft]);

  const dropdownStyle = {
    position: "fixed",
    marginLeft: -scrollLeft + clientWidth - 160,
    marginTop: -scrollTop,
  };

  return (
    <div style={{ position: "relative" }} ref={ref}>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggle();
        }}
      >
        {clickComponent}
      </div>
      <div
        style={{
          width: 160,
          padding,
          boxShadow: "0px 8px 16px -4px rgba(22, 34, 51, 0.08)",
          borderRadius: 8,
          visibility: isOpen ? "initial" : "hidden",
          backgroundColor: "white",
          height: isOpen ? modalHeight : 0,
          // transition: isOpen ? "all 0.4s linear" : "",
          overflow: "hidden",
          zIndex: 2000,
          ...dropdownStyle,
          ...moreDropdownStyle,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            ...contentDivStyle,
          }}
        >
          {content}
        </div>
        <div>
          <div
            ref={(e) => {
              if (!e) return;
              setClientHeight((prev) => {
                if (prev == e.clientHeight) return prev;
                return e.clientHeight;
              });
            }}
          >
            {component(handleClick)}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoadingScreenImport = ({ isPayroll = false }) => {
  const { setHeader } = useHeader();
  const { isOpen: isOpenClose, toggle: toggleClose } = useModalHook();

  useEffect(() => {
    setHeader({
      type: "close",
      onCloseProps: () => toggleClose(),
      middleComponent: (
        <ProgressBarJack
        // arrayStep={isPayroll ? stepArrayFunc(1) : stepArrayLocalFunc(1)}
        />
      ),
    });
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ marginTop: 140 }}
    >
      <img src={validateGif} className="mb-0" style={{ width: 240 }} />
      <GothamMedium className="font20 mb-3">
        Processing your data...
      </GothamMedium>
      <div style={{ maxWidth: 438 }}>
        <GothamRegular
          style={{ color: jackColors.neutral700, textAlign: "center" }}
        >
          It may take a while. Please keep this page open until we finish
          processing.
        </GothamRegular>
      </div>
      <ModalConfirmationCloseLocal
        toggle={toggleClose}
        isOpen={isOpenClose}
        product="local"
        customText={
          <>
            You can always visit{" "}
            <span style={{ fontFamily: "GothamMedium" }}>Draft</span> to edit
            and submit this request.
          </>
        }
      />
    </div>
  );
};

export const dateOnlyIso = (date) => {
  const dateObj = new Date(date);

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const generateTimeOptions = (from = 0, to = 24) => {
  const options = [];
  for (let hour = from; hour <= to; hour++) {
    const formattedHour = hour.toString().padStart(2, "0");
    const label = `${formattedHour}:00`;
    options.push({ label, value: label });
  }
  return options;
};

export const generateTimeOptionsComplex = (date = new Date(), endTime) => {
  if (!Boolean(date)) return [];

  const isToday = todayChecker(date);
  const defaultOptions = generateTimeOptions(0, 23);
  if (isToday) {
    const currentDate = new Date();
    const hour = currentDate?.getHours();

    if (hour <= 6) return defaultOptions;
    if (hour >= 23) return [];

    return generateTimeOptions(hour + 1, endTime || 23);
  }

  return defaultOptions;
};

export const todayChecker = (date = new Date()) => {
  const targetDate = date?.getDate();
  const today = new Date().getDate();
  if (targetDate === today) return true;
  return false;
};
