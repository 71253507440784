import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { GothamMedium } from "../../../../../components/Text";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { TransactionFilterDateCustom } from "./dropdowns/date/parent";

const TransactionFilterAdd = dynamic(() => import("./dropdowns/add"), {
  ssr: false,
});
const TransactionFilterAmount = dynamic(
  () => import("./dropdowns/amount/amount"),
  { ssr: false }
);
const TransactionFilterCards = dynamic(() => import("./dropdowns/card"), {
  ssr: false,
});
const TransactionFilterDate = dynamic(() => import("./dropdowns/date/parent"), {
  ssr: false,
});
const TransactionFilterFetch = dynamic(() => import("./dropdowns/fetch"), {
  ssr: false,
});
const TransactionFilterRadio = dynamic(() => import("./dropdowns/radio"), {
  ssr: false,
});
const TransactionFilterStatus = dynamic(
  () => import("./dropdowns/status/parent"),
  { ssr: false }
);
const TransactionFilterCountry = dynamic(
  () => import("./dropdowns/country/parent"),
  { ssr: false }
);

export const TransactionFilterButtons = ({
  activeFilters,
  filters,
  onClick,
  className = "",
  isModal,
  woResetFilter,
  woAdd,
  loading,
  runQueryName = "runQuery",
}) => {
  const { t } = useTranslation("api-history/api-history");
  const queries = filters
    .map((item) => {
      const { name, queryNames = [] } = item;
      return [...queryNames, name];
    })
    .flat(1);

  const { query, push } = useRouter();

  const activeQueryKeys = queries.filter((key) => !!query[key]);

  const showReset = activeQueryKeys.length;

  const [globalResetTriggered, setGlobalResetTriggered] = useState(false);

  const onReset = () => {
    let obj = query;
    activeQueryKeys.forEach((key) => delete obj[key]);
    push({ query: { ...obj, [runQueryName]: false } });
    push({ query: { ...obj, [runQueryName]: true } });
    eventsTracker("reset_filter_global");
    setGlobalResetTriggered(true);
    onClick();
  };

  const inactiveFilters = filters
    .filter((item) => {
      const { name } = item;
      return !activeFilters.includes(name);
    })
    .filter((item) => item);

  let activeFiltersArray = [
    ...filters.filter(({ name }) => activeFilters.includes(name)),
    ...[{ add: true }, { reset: showReset }],
  ];

  if (woAdd) activeFiltersArray = activeFiltersArray.filter(({ add }) => !add);

  return (
    <div
      className="w-100"
      style={{
        paddingRight: 16,
        paddingLeft: 16,
      }}
    >
      <Row
        style={{
          rowGap: 12,
          columnGap: 12,
          display: "flex",
          flexWrap: "wrap",
        }}
        className={`d-flex align-items-center ${className}`}
      >
        {activeFiltersArray.map((item, index) => {
          const component = (() => {
            if (!item) return null;
            const { type, add, reset } = item;

            const props = {
              isModal,
              fetchLoading: loading,
              runQueryName,
              globalReset: globalResetTriggered,
              globalResetTrigger: setGlobalResetTriggered,
              ...item,
            };

            const isCard = type == "card";
            const isStatus = type == "status";
            const isFetch = type == "fetch";
            const isAmount = type == "amount";
            const isRadio = type == "radio";
            const isDate = type == "date";
            const isDateCustom = type == "dateCustom";
            const isCurrency = type == "currencies";

            if (isStatus) return <TransactionFilterStatus {...props} />;
            if (isFetch) return <TransactionFilterFetch {...props} />;
            if (isAmount) return <TransactionFilterAmount {...props} />;
            if (isRadio) return <TransactionFilterRadio {...props} />;
            if (isCard) return <TransactionFilterCards {...props} />;
            if (isDate) return <TransactionFilterDate {...props} />;
            if (isDateCustom) return <TransactionFilterDateCustom {...props} />;
            if (isCurrency) return <TransactionFilterCountry {...props} />;

            if (add) {
              return (
                <TransactionFilterAdd
                  isModal={isModal}
                  array={inactiveFilters}
                  onClick={onClick}
                />
              );
            }

            if (reset && !woResetFilter)
              return (
                <GothamMedium
                  id="reset-filter-button"
                  className="font12 hover"
                  style={{
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                  onClick={onReset}
                >
                  {t("Reset Filter")}
                </GothamMedium>
              );
            return null;
          })();

          // added this to prevent stray empty divs -> weird whitespace because of flex gap

          if (!component) return null;

          return (
            <Col key={index} xs="auto" style={{ padding: 0 }}>
              {component}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
