import { jackColors } from "assets/colors";
import { Banner } from "components/typescript/Banner";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "components/typescript/Text";
import React, { useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ContentModalCustomPayrollProps,
  DataContentType,
  DescTextArrayDetailType,
  TextArrProps,
} from "types/modals/guide_template_jack/type";
import DownloadTalentaTemplate from "../../../public/images/guide-modal-download-talenta-payroll.svg";
import UploadTalentaTemplate from "../../../public/images/guide-modal-upload-talenta-payroll.svg";
import ViewportList from "react-viewport-list";
import JackIcons from "assets/jackIcons/typescript/parent";
import { TableJackSimple } from "components/typescript/Table/parent";
import { TransitionTr } from "components/typescript/Transition";

export const TextArr: React.FC<TextArrProps> = ({
  arr,
  customStyles,
  onClick,
  t = (item) => item,
}) => {
  return (
    <div>
      {arr.map((string, index) => {
        const isOdd = index % 2 === 1;
        if (isOdd) {
          return (
            <TextInlineMedium
              key={index}
              className={`${customStyles?.className ?? "font14"}`}
              style={{ color: jackColors.neutral900, ...customStyles }}
              onClick={() => onClick && onClick(0)}
            >
              {t(string as string)}
            </TextInlineMedium>
          );
        }
        return (
          <GothamRegular className={`${customStyles?.className ?? "font14"}`}>
            {t(string as string)}
          </GothamRegular>
        );
      })}
    </div>
  );
};

export const detailSecondStep: (string | JSX.Element)[] = [
  "Employee ID",
  "Full Name",
  "Job Position",
  "Take Home Pay",
  "Bank Account",
  "Bank Name",
  <TextArr
    key="email"
    arr={["Email ", "(Optional)"]}
    customStyles={{ color: jackColors.neutral700, className: "font12" }}
  />,
];

export const detailSecondStepModalLocalFunc = ({
  t,
  type,
}: {
  t: (item: string) => string;
  type: string;
}): (string | JSX.Element)[] => {
  switch (type) {
    case "invalid_format_csv":
      return [
        <GothamRegular key="multistyle_2">
          <Trans
            i18nKey={t(`multistyle_2`)}
            components={{
              GothamMedium: (
                <GothamMedium
                  style={{
                    color: jackColors.black34,
                    display: "inline-flex",
                    flexDirection: "row",
                  }}
                />
              ),
              GothamMediumRed: (
                <GothamMedium
                  style={{
                    color: jackColors.redDC,
                    display: "inline-flex",
                    flexDirection: "row",
                  }}
                />
              ),
            }}
          />
        </GothamRegular>,
        <GothamRegular key="multistyle_3">
          <Trans
            i18nKey={t(`multistyle_3`)}
            components={{
              GothamMedium: (
                <GothamMedium
                  style={{
                    color: jackColors.black34,
                    display: "inline-flex",
                    flexDirection: "row",
                  }}
                />
              ),
              GothamMediumRed: (
                <GothamMedium
                  style={{
                    color: jackColors.redDC,
                    display: "inline-flex",
                    flexDirection: "row",
                  }}
                />
              ),
            }}
          />
        </GothamRegular>,
      ];

    case "guidance_template_csv":
      return [
        <GothamRegular key="style_text_21">
          <Trans
            i18nKey={t(`dynamic.style_text_21`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.black34,
                }}
              />,
            ]}
          />
        </GothamRegular>,
        <GothamRegular key="style_text_22">
          <Trans
            i18nKey={t(`dynamic.style_text_22`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.black34,
                }}
              />,
            ]}
          />
        </GothamRegular>,
        <GothamRegular key="style_text_23">
          <Trans
            i18nKey={t(`dynamic.style_text_23`)}
            components={{
              GothamMedium: (
                <GothamMedium
                  style={{
                    color: jackColors.black34,
                    display: "inline-flex",
                    flexDirection: "row",
                  }}
                />
              ),
              GothamMediumRed: (
                <GothamMedium
                  style={{
                    color: jackColors.redDC,
                    display: "inline-flex",
                    flexDirection: "row",
                  }}
                />
              ),
            }}
          />
        </GothamRegular>,
        <GothamRegular key="style_text_24">
          <Trans
            i18nKey={t(`dynamic.style_text_24`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.black34,
                }}
              />,
            ]}
          />
        </GothamRegular>,
        <GothamRegular key="style_text_25">
          <Trans
            i18nKey={t(`dynamic.style_text_25`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.black34,
                }}
              />,
            ]}
          />
        </GothamRegular>,
      ];

    default:
      return [];
  }
};

export const descTextArray = (
  t: (item: string) => string
): DescTextArrayDetailType[] => {
  return [
    {
      titleDesc: t(`Download Excel template directly from Jack`),
      subDesc: t(
        `Use the official template available on Jack's platform & keep the column order and structure as is.`
      ),
    },
    {
      titleDesc: t(`Fill in the Columns Correctly`),
      subDesc: t(
        `Make sure each column is filled out in the correct format, as shown below:`
      ),
      loopDesc: [
        <GothamRegular key="style_text_26">
          <Trans
            i18nKey={t(`dynamic.style_text_26`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.black34,
                }}
              />,
            ]}
          />
        </GothamRegular>,
        <GothamRegular key="style_text_27">
          <Trans
            i18nKey={t(`dynamic.style_text_27`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.black34,
                }}
              />,
            ]}
          />
        </GothamRegular>,
        <GothamRegular key="style_text_28">
          <Trans
            i18nKey={t(`dynamic.style_text_28`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.black34,
                }}
              />,
            ]}
          />
        </GothamRegular>,
        <GothamRegular key="style_text_29">
          <Trans
            i18nKey={t(`dynamic.style_text_29`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.black34,
                }}
              />,
            ]}
          />
        </GothamRegular>,
        <GothamRegular key="multistyle_4">
          <Trans
            i18nKey={t(`multistyle_4`)}
            components={{
              GothamMedium: (
                <GothamMedium
                  style={{
                    color: jackColors.black34,
                    display: "inline-flex",
                    flexDirection: "row",
                  }}
                />
              ),
              GothamMediumRed: (
                <GothamMedium
                  style={{
                    color: jackColors.redDC,
                    display: "inline-flex",
                    flexDirection: "row",
                  }}
                />
              ),
            }}
          />
        </GothamRegular>,
        <GothamRegular key="style_text_30">
          <Trans
            i18nKey={t(`dynamic.style_text_30`)}
            components={[
              <span
                style={{
                  fontFamily: "GothamMedium",
                  color: jackColors.black34,
                }}
              />,
            ]}
          />
        </GothamRegular>,
      ],
      helperDesc: (
        <div style={{ margin: "0px 20px" }}>
          <Banner
            msg={
              <Trans
                i18nKey={t("dynamic.style_text_16")}
                components={[
                  <span
                    style={{
                      fontFamily: "GothamMedium",
                      cursor: "pointer",
                      color: jackColors.black34,
                    }}
                    // onClick={() =>
                    //   handleClick({ type: "open_guide_modal", values: "" })
                    // }
                  />,
                ]}
              />
            }
            type="custom-icon"
            textIconStyleProps={{ alignItems: "flex-start" }}
          />
        </div>
      ),
    },
    {
      titleDesc: t(`Do a Double Check`),
      subDesc: t(
        `Double-check each row and column for error(s) before uploading the file. `
      ),
    },
    {
      titleDesc: t(`Save File as (.xlsx)`),
      subDesc: t(
        `Save the Excel file in (.xlsx) format, just like the original template, because the system may not recognize other formats.`
      ),
    },
    {
      titleDesc: t(`Upload File to Jack`),
      subDesc: t(
        `Once all data has been filled in and checked, upload the file to Jack's system.`
      ),
    },
  ];
};

export const detailPointSecondStep = (
  onChange: (index: number) => void,
  t: (item: string) => string
): (string | JSX.Element)[] => [
  <GothamRegular key="style_text_15">
    <Trans
      i18nKey={t(`dynamic.style_text_15`)}
      components={[
        <span
          style={{
            fontFamily: "GothamMedium",
            color: jackColors.black34,
          }}
        />,
      ]}
    />
  </GothamRegular>,
  <GothamRegular key="style_text_16">
    <Trans
      i18nKey={t(`dynamic.style_text_16`)}
      components={[
        <span
          style={{
            fontFamily: "GothamMedium",
            color: jackColors.black34,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => onChange(0)}
        />,
      ]}
    />
  </GothamRegular>,
  "Make sure these fields are included and correctly filled in: ",
];

export const useDataContentGuidanceLocal = (data: DataContentType[] = []) => {
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const tbodyRef = useRef(null);

  const tableTotalWidth = 1136;
  const tableWidth = (number: number) => `${(number / tableTotalWidth) * 100}%`;

  const headerArr = [
    {
      label: tLocalTransfer("Column"),
      width: tableWidth(128),
      textAlign: "left",
    },
    {
      label: tLocalTransfer("❌  Most Common Errors"),
      width: tableWidth(209),
      textAlign: "left",
    },
    {
      label: tLocalTransfer("✅  How to Input Data Correctly"),
      width: tableWidth(209),
      textAlign: "left",
    },
  ];

  // Map `DataContentType` into renderable data for the table
  const bodyTable = data.map((item) => {
    const tableRow = item.content.map(
      ({ text, optionalText, isBulletNumbering }) => {
        let textContent;

        // Handle text field (string or JSX)
        if (typeof text === "string") {
          textContent = text; // Directly use the string
        } else if (React.isValidElement(text)) {
          textContent = <>{text}</>; // Renderable ReactNode
        } else if (Array.isArray(text)) {
          textContent = text.map((jsx, index) => <div key={index}>{jsx}</div>);
        }

        // Handle optional text (string or JSX)
        let optionalTextContent;
        if (typeof optionalText === "string") {
          optionalTextContent = optionalText;
        } else if (React.isValidElement(optionalText)) {
          optionalTextContent = <>{optionalText}</>;
        }

        return {
          textContent,
          optionalTextContent,
          isBulletNumbering,
        };
      }
    );

    return {
      key: item.key,
      tableRow,
    };
  });

  return { headerArr, tbodyRef, bodyTable };
};

export const contentModalCustomPayroll = ({
  onChange,
  t = (item) => item,
  isIndo = false,
  type = "talenta",
  data,
}: ContentModalCustomPayrollProps): {
  title: string;
  img: string | undefined;
  desctext: JSX.Element;
}[] => {
  const detailPointSecondStepVar = detailPointSecondStep(onChange, t);
  const descTextArrayDetail: DescTextArrayDetailType[] = descTextArray(t);
  const detailSecondStepModalLocal: (string | JSX.Element)[] =
    detailSecondStepModalLocalFunc({ t, type: "guidance_template_csv" });
  const { headerArr, bodyTable, tbodyRef } = useDataContentGuidanceLocal(data);
  const detailFirstStep: (string | JSX.Element)[] = [
    "Log in to the Talenta dashboard",
    <GothamRegular key="style_text_9">
      <Trans
        i18nKey={t(`dynamic.style_text_9`)}
        components={[
          <span
            style={{
              fontFamily: "GothamMedium",
              color: jackColors.black34,
            }}
          />,
        ]}
      />
    </GothamRegular>,
    <GothamRegular key="style_text_10">
      <Trans
        i18nKey={t(`dynamic.style_text_10`)}
        components={[
          <span
            style={{
              fontFamily: "GothamMedium",
              color: jackColors.black34,
            }}
          />,
        ]}
      />
    </GothamRegular>,
    <GothamRegular key="style_text_11">
      <Trans
        i18nKey={t(`dynamic.style_text_11`)}
        components={[
          <span
            style={{
              fontFamily: "GothamMedium",
              color: jackColors.black34,
            }}
          />,
        ]}
      />
    </GothamRegular>,
    <GothamRegular key="style_text_12">
      <Trans
        i18nKey={t(`dynamic.style_text_12`)}
        components={[
          <span
            style={{
              fontFamily: "GothamMedium",
              color: jackColors.black34,
            }}
          />,
        ]}
      />
    </GothamRegular>,
    <GothamRegular key="style_text_13">
      <Trans
        i18nKey={t(`dynamic.style_text_13`)}
        components={[
          <span
            style={{
              fontFamily: "GothamMedium",
              color: jackColors.black34,
            }}
          />,
        ]}
      />
    </GothamRegular>,
    "Ensure all payroll data is correct",
    <GothamRegular key="style_text_14">
      <Trans
        i18nKey={t(`dynamic.style_text_14`)}
        components={[
          <span
            style={{
              fontFamily: "GothamMedium",
              color: jackColors.black34,
            }}
          />,
        ]}
      />
    </GothamRegular>,
    "The file is ready to be uploaded to Jack Payroll",
  ];
  // Switch statement with content returned for the 'talenta' type
  switch (type) {
    case "talenta":
      return [
        {
          title: t("How to download Talenta Salary Detail"),
          img: DownloadTalentaTemplate,
          desctext: (
            <>
              <GothamRegular
                className="font14"
                style={{ color: jackColors.neutral900, textAlign: "justify" }}
              >
                {t(
                  "Jack now allows direct payroll data imports from other HRIS or payment gateways."
                )}
              </GothamRegular>
              <GothamRegular
                className="font14"
                style={{
                  color: jackColors.neutral900,
                  margin: "20px 0px",
                  textAlign: "justify",
                  marginRight: 20,
                }}
              >
                {t(
                  "Download the Salary Detail from your Talenta dashboard, upload it to Jack Payroll, and let our system automatically handle the data extraction. This means no more manual data entry for you."
                )}
              </GothamRegular>
              <GothamMedium
                className="font14"
                style={{
                  color: jackColors.neutral900,
                  textAlign: "justify",
                  marginRight: 20,
                  marginBottom: 10,
                }}
              >
                {t(
                  "You can follow these easy steps to download the Salary Detail report from your Talenta dashboard:"
                )}
              </GothamMedium>
              {detailFirstStep.map((string, index) => (
                <div
                  key={index}
                  className="d-flex flex-row"
                  style={{ marginBottom: 12 }}
                >
                  <div
                    style={{
                      marginRight: 9,
                      paddingLeft: 4,
                    }}
                  >
                    <GothamRegular>{index + 1}.</GothamRegular>
                  </div>
                  <div>
                    <GothamRegular>
                      {typeof string === "string" ? t(string) : string}
                    </GothamRegular>
                  </div>
                </div>
              ))}
            </>
          ),
        },
        {
          title: t("Upload the downloaded file to Jack Payroll"),
          img: UploadTalentaTemplate,
          desctext: (
            <>
              <GothamMedium
                className="font14"
                style={{
                  color: jackColors.neutral900,
                  textAlign: "justify",
                  marginBottom: 20,
                }}
              >
                {t(
                  "Here's what you need to check before uploading the Talenta Salary Detail:"
                )}
              </GothamMedium>
              {detailPointSecondStepVar.map((string, index) => (
                <div
                  key={index}
                  className="d-flex flex-row"
                  style={{
                    marginBottom:
                      index === detailPointSecondStepVar.length - 1 ? 8 : 12,
                  }}
                >
                  <div
                    style={{
                      marginRight: 9,
                      paddingLeft: 6,
                    }}
                  >
                    <GothamRegular>{index + 1}.</GothamRegular>
                  </div>
                  <div>
                    <GothamRegular>
                      {typeof string === "string" ? t(string) : string}
                    </GothamRegular>
                  </div>
                </div>
              ))}
              <ol
                style={{
                  paddingLeft: 20,
                  listStyleType: "disc",
                  marginLeft: 30,
                }}
              >
                {detailSecondStep.map((string, index) => (
                  <li key={index} style={{ marginBottom: 8 }}>
                    <GothamMedium>{string}</GothamMedium>
                  </li>
                ))}
              </ol>
            </>
          ),
        },
      ];

    case "modal_csv":
      return [
        {
          title: t("How to Use Jack's Excel Template"),
          img: undefined, //undefined, //DownloadTalentaTemplate,
          desctext: (
            <>
              {descTextArrayDetail.map((item, index) => {
                const { titleDesc, subDesc, loopDesc, helperDesc } = item;
                return (
                  <div key={index} style={{ marginBottom: 24 }}>
                    <div
                      style={{
                        marginRight: 9,
                        paddingLeft: 4,
                      }}
                      className="d-flex flex-row"
                    >
                      <GothamMedium
                        className="font14"
                        style={{
                          color: jackColors.neutral900,
                          textAlign: "justify",
                          marginRight: 20,
                          marginBottom: 8,
                        }}
                      >
                        {index + 1}. {titleDesc}
                      </GothamMedium>
                    </div>
                    <div style={{ marginLeft: 18 }}>
                      <GothamRegular>{subDesc}</GothamRegular>
                    </div>
                    <div>
                      {loopDesc && loopDesc.length > 0 && (
                        <ol
                          style={{
                            paddingLeft: 20,
                            listStyleType: "disc",
                            marginLeft: 16,
                          }}
                        >
                          {loopDesc.map((string, index) => {
                            const isLastIndex = index === loopDesc.length - 1;
                            return (
                              <li
                                key={index}
                                style={{
                                  marginTop: 12,
                                  ...(isLastIndex && { marginBottom: 12 }),
                                }}
                              >
                                <GothamRegular>{string}</GothamRegular>
                              </li>
                            );
                          })}
                        </ol>
                      )}
                    </div>
                    {helperDesc && <>{helperDesc}</>}
                  </div>
                );
              })}
              <div style={{ margin: "0px 20px" }}>
                <Banner
                  noBorder
                  title={
                    <GothamMedium className="font14">
                      {t(`Need Help?`)}
                    </GothamMedium>
                  }
                  msg={
                    <GothamRegular className="font14">
                      <Trans
                        i18nKey={t("dynamic.dynamic_text_29")}
                        components={[
                          <a
                            href="https://wa.me/6285282051660"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                              color: "inherit", // Optional: Keeps the text color consistent
                            }}
                          />,
                        ]}
                      />
                    </GothamRegular>
                  }
                  type="help-center"
                  textIconStyleProps={{ alignItems: "flex-start" }}
                  msgStyle={{ color: jackColors.black34 }}
                />
              </div>
            </>
          ),
        },
        {
          title: t("Most Common Errors & How to Input Data Correctly"),
          img: undefined,
          desctext: (
            <>
              <div style={{ marginTop: 24 }}>
                <TableJackSimple
                  style={{ overflow: "hidden" }}
                  headerTable={[
                    {
                      label: "Column",
                      width: "128px",
                      textAlign: "left",
                    },
                    {
                      label: "❌  Most Common Errors",
                      width: "209px",
                      textAlign: "left",
                    },
                    {
                      label: "✅  How to Input Data Correctly",
                      width: "209",
                      textAlign: "left",
                    },
                  ]}
                  tbodyRef={tbodyRef}
                  bodyComponent={
                    <>
                      <ViewportList viewportRef={tbodyRef} items={bodyTable}>
                        {({ tableRow }, index) => {
                          return (
                            <TransitionTr
                              isQuick
                              counter={index}
                              perPage={20}
                              key={index}
                            >
                              {tableRow.map((cell, idx) => {
                                return (
                                  <td
                                    key={idx}
                                    style={{
                                      borderRight:
                                        tableRow.length - 1 !== idx
                                          ? "1px solid rgba(230, 230, 232, 1)"
                                          : undefined,
                                    }}
                                  >
                                    <div
                                      style={{
                                        justifyContent: "flex-start",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexDirection: "row",
                                        height: "100%",
                                      }}
                                    >
                                      <GothamRegular className={"font14"}>
                                        {/* Check if isBulletNumbering is true */}
                                        {cell.isBulletNumbering ? (
                                          <ol
                                            style={{
                                              paddingLeft: 20,
                                              listStyleType: "disc",
                                              fontSize: 10,
                                              marginLeft: -5,
                                            }}
                                          >
                                            {Array.isArray(cell.textContent) &&
                                              cell.textContent.map(
                                                (text, bulletIdx) => (
                                                  <li
                                                    key={bulletIdx}
                                                    style={{
                                                      marginBottom: 4,
                                                    }}
                                                  >
                                                    {text}
                                                  </li>
                                                )
                                              )}
                                          </ol>
                                        ) : (
                                          <>
                                            {/* Render textContent directly if not bullet numbering */}
                                            {cell.textContent}
                                            {cell.optionalTextContent && (
                                              <div>
                                                {cell.optionalTextContent}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </GothamRegular>
                                    </div>
                                  </td>
                                );
                              })}
                            </TransitionTr>
                          );
                        }}
                      </ViewportList>
                    </>
                  }
                  thStyle={{
                    backgroundColor: jackColors.neutral400,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    height: "100%",
                    textAlign: "left",
                  }}
                  labelStyle={{
                    textAlign: "left",
                    fontFamily: "GothamMedium",
                    color: jackColors.black34,
                  }}
                  noHeaderPadding
                  isWithVerticalLines
                  classNameLabel="font14"
                />
              </div>
              <div
                style={{
                  padding: 16,
                  backgroundColor: "rgba(241, 241, 241, 0.50)",
                  borderRadius: 4,
                  margin: "24px 0px",
                }}
              >
                <GothamMedium className="font14" style={{ marginBottom: 16 }}>
                  {t("Don’ts (Things to Avoid)")}
                </GothamMedium>
                {detailSecondStepModalLocal.map((element, index) => {
                  const isLastIndex =
                    detailSecondStepModalLocal.length - 1 == index;
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: isLastIndex ? 0 : 12,
                      }}
                    >
                      <JackIcons
                        name="block"
                        fill={jackColors.redDC}
                        style={{ height: 20, width: 14, marginRight: 4 }}
                        className="mb-4"
                      />
                      {element}
                    </div>
                  );
                })}
              </div>
              <div>
                <Banner
                  noBorder
                  title={
                    <GothamMedium className="font14">
                      {t(`Need Help?`)}
                    </GothamMedium>
                  }
                  msg={
                    <GothamRegular className="font14">
                      <Trans
                        i18nKey={t("dynamic.dynamic_text_29")}
                        components={[
                          <a
                            href="https://wa.me/6285282051660"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                              color: "inherit", // Optional: Keeps the text color consistent
                            }}
                          />,
                        ]}
                      />
                    </GothamRegular>
                  }
                  type="help-center"
                  textIconStyleProps={{ alignItems: "flex-start" }}
                  msgStyle={{ color: jackColors.black34 }}
                />
              </div>
            </>
          ),
        },
      ];

    default:
      return [];
  }
};

export const guidanceModalType = (
  type: string,
  t: (key: string) => string
): { title: string }[] => {
  if (type == "modal_csv") {
    return [
      { title: t("1.  How to Use Jack's Excel Template") },
      { title: t("2.  Things to Note & Do") },
    ];
  }
  if (type == "talenta") {
    return [
      { title: t("1. Download Talenta Salary Detail") },
      { title: t("2. Upload downloaded file to Jack") },
    ];
  }
  return [
    { title: t("1. Download Talenta Salary Detail") },
    { title: t("2. Upload downloaded file to Jack") },
  ];
};
