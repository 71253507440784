import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { jackColors } from "../../assets/colors";
import LoadingSpinner from "../../components/Loading";
import { useGetAuth } from "../AuthContext";
import { BrowserHeader } from "./browserHeader";
import { CloseHeader } from "./buttons/close";
import { CreateAndBellHeader } from "./buttons/create";
import { useLastScreen, useLayoutSidebars } from "./logics";
import { Sidebar } from "./sidebar/parent";

const LayoutContext = createContext({
  setComponent: () => {},
  scrollProps: {},
  lastScreenObj: {},
  clientWidth: 0,
  clientHeight: 0,
});

export const LayoutProvider = ({ children }) => {
  const { push, pathname } = useRouter();

  const { hasSidebar } = useLayoutSidebars();
  const { user } = useGetAuth();
  const isResponsive =
    [
      "/tnc-login",
      "/tnc-subscription",
      "/terms-and-conditions",
      "/terms-and-conditions/employee",
      "/terms-and-conditions/admin",
      "/login",
      "/register",
      "/change-password",
      "/forgot-password",
      "/account/create-pin",
      "/register/success",
      "/new-register",
      "/new-register/success",
      "/create-password",
    ].includes(pathname) || pathname.includes("kyb-jack");

  const isBlocked = user?.status == "blocked";

  // accepted component shapes:
  // 1. {screen:<div>hi</div>}
  // 2. {
  //   type: "createAndBell",
  //   array: [{ label: "tes", value: "tes" }],
  //   onClick: (value) => value,
  // }
  // 3. {type:'close'}
  const [component, setComponent] = useState({});
  const { lastScreenObj, onClick } = useLastScreen();

  const ref = useRef();

  const scrollTo = (param) => ref?.current?.scrollTo(param);
  const scrollToTop = () => ref?.current?.scrollTo({ top: 0 });

  useEffect(() => {
    setComponent({});
    scrollToTop();
  }, [pathname]);

  const childrenDecider = () => {
    // if (!isReady) return <LoadingSpinner />;
    return children;
  };

  const [scrollProps, setScrollProps] = useState({});

  const onClose = () => {
    if (isEmpty(lastScreenObj)) return push("/home");
    return push(lastScreenObj);
  };

  const componentDecider = () => {
    const { screen, type, middleComponent, onCloseProps, woIcon, style } =
      component;
    const isCreateAndBell = type == "createAndBell";
    const isClose = type == "close";
    const isIndex =
      pathname == "/dashboard" || pathname == "/company-transaction";

    if (screen) return screen;

    if (isClose)
      return (
        <CloseHeader
          middleComponent={middleComponent}
          woIcon={woIcon}
          style={style}
          onClose={() => (!!onCloseProps ? onCloseProps() : onClose())}
        />
      );

    if (isCreateAndBell) return <CreateAndBellHeader style={style} />;

    if (isIndex) return <div style={{ width: "100%", height: 80 }}></div>;
    return null;
  };

  const [divRef, setDivRef] = useState(null);

  const clientWidth = divRef?.clientWidth || 0;
  const clientHeight = divRef?.clientHeight || 0;

  const [isLoadedJS, setIsLoadedJS] = useState(false);

  useEffect(() => {
    setIsLoadedJS(true);
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        setComponent,
        scrollProps,
        lastScreenObj,
        clientWidth,
        clientHeight,
        onClose,
        scrollTo,
        scrollToTop,
      }}
    >
      <BrowserHeader />
      <div
        className="d-flex justify-content-center w-100 noselect"
        ref={ref}
        style={{
          height: "100vh",
          overflowY: "auto",
          backgroundColor: jackColors.greyFC,
          ...(isResponsive ? {} : { minWidth: 1200 }),
        }}
        onScroll={(e) => {
          const { scrollTop, scrollHeight, clientHeight, scrollLeft } =
            e.target || {};
          setScrollProps({
            scrollTop,
            scrollHeight,
            clientHeight,
            scrollLeft,
          });
        }}
      >
        {isLoadedJS ? (
          <div className="d-flex" style={{ maxWidth: 1920, width: "100%" }}>
            <Sidebar onClick={onClick} />
            <div
              style={{
                width: hasSidebar ? "calc(100% - 240px)" : "100%",
                transition: "all 0.4s linear",
              }}
              ref={(e) => setDivRef(e)}
            >
              <div>
                {componentDecider()}
                {childrenDecider()}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh", width: "100vw" }}
          >
            <LoadingSpinner />
          </div>
        )}
      </div>
    </LayoutContext.Provider>
  );
};

export const useHeader = () => {
  const { setComponent } = useContext(LayoutContext);

  const setHeader = ({
    screen,
    type,
    middleComponent,
    onCloseProps,
    woIcon,
    style,
  }) =>
    setTimeout(
      () =>
        setComponent({
          screen,
          type,
          middleComponent,
          onCloseProps,
          woIcon,
          style,
        }),
      200
    );

  return { setHeader };
};

export const useLayout = () => {
  const {
    scrollProps,
    lastScreenObj,
    clientWidth,
    clientHeight,
    onClose,
    scrollTo,
    scrollToTop,
  } = useContext(LayoutContext);
  const { scrollTop = 0, scrollHeight = 0, scrollLeft = 0 } = scrollProps || {};

  const canRefetch = scrollTop + clientHeight > scrollHeight - 30;

  return {
    canRefetch,
    scrollTop,
    scrollLeft,
    lastScreenObj,
    clientWidth,
    onClose,
    clientHeight,
    scrollTo,
    scrollToTop,
    clientHeight,
  };
};
