import { jackColors } from "assets/colors";
import { GothamRegular } from "components/Text";
import React, { ReactNode, RefObject, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useUploadReceiptScreenType } from "modals/smartUploadReceiptModal/logics";

// import GothamRegular from "./GothamRegular"; // Make sure you import this from the right file
// import jackColors from "./jackColors"; // Same for this

interface HeaderItem {
  label: string | ReactNode;
  width: string;
  textAlign?: CSSProperties["textAlign"];
}

interface TableJackSimpleProps {
  headerTable?: HeaderItem[];
  bodyComponent?: ReactNode;
  tbodyRef: RefObject<HTMLTableSectionElement>;
  style?: CSSProperties;
  tableStyle?: CSSProperties;
  trStyle?: CSSProperties;
  thStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  noHeaderPadding?: boolean;
  isDumb?: boolean;
  isAllowOverflow?: boolean;
  classNameLabel?: string;
  [key: string]: any; // For any additional props
}

export const TableJackSimple: React.FC<TableJackSimpleProps> = ({
  headerTable = [],
  bodyComponent = null,
  tbodyRef,
  style = {},
  tableStyle = {},
  trStyle = {},
  thStyle = {},
  labelStyle = {},
  noHeaderPadding = false,
  isDumb = false,
  isAllowOverflow = false,
  classNameLabel,
  isWithVerticalLines = false,
  ...props
}) => {
  const filteredHeaders = headerTable.filter((item) => item);
  const { t } = useTranslation("reimbursement/reimbursement");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const { isLocalTransfer } = useUploadReceiptScreenType();
  const tDecider = isLocalTransfer ? tLocalTransfer : t;

  const maskingHeader = (headerItem: HeaderItem) => {
    if (headerItem?.label === "Bank") return "Bank Name";
    if (typeof headerItem?.label === "string") {
      return tDecider(headerItem?.label);
    }
    return headerItem?.label; // Directly return if not a string
  };

  return (
    <div
      className={styles[isDumb ? "table-container-dumb" : "table-container"]}
      style={{
        position: "relative",
        overflow: isAllowOverflow ? "visible" : "clip",
        width: "100%",
        ...style,
      }}
      {...props}
    >
      <table
        style={{
          width: "100%",
          tableLayout: "fixed",
          position: "relative",
          ...tableStyle,
        }}
        className={styles[isDumb ? "table-seamless-dumb" : "table-seamless"]}
      >
        <thead>
          <tr style={{ height: 40, maxHeight: 40, ...trStyle }}>
            {filteredHeaders.map((headerItem, index) => {
              if (!headerItem) return null;

              return (
                <th
                  key={index}
                  className={noHeaderPadding ? "no-padding" : "default-padding"}
                  style={{
                    width: headerItem.width,
                    textAlign: headerItem.textAlign, // as CSSProperties["textAlign"],
                    backgroundColor: isDumb ? "transparent" : "white",
                    height: 40,
                    minHeight: 40,
                    ...(isWithVerticalLines && {
                      borderRight:
                        index === filteredHeaders.length - 1
                          ? undefined
                          : "1px solid #e6e6e8",
                    }),

                    ...thStyle,
                  }}
                >
                  <GothamRegular
                    style={{ color: jackColors.grey90, ...labelStyle }}
                    className={classNameLabel ?? "font12"}
                  >
                    {maskingHeader(headerItem)}
                  </GothamRegular>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody ref={tbodyRef}>{bodyComponent}</tbody>
      </table>
    </div>
  );
};
