import React from "react";
import { useTranslation } from "react-i18next";
import { GothamMedium } from "components/typescript/Text";
import { jackColors } from "assets/colors";
import JackIcons from "assets/jackIcons/typescript/parent";
import { GuidanceModalTemplateProps } from "types/modals/guide_template_jack/type";
import { guidanceModalType } from "./constant";
import { useUploadReceiptScreenType } from "modals/smartUploadReceiptModal/logics";

const GuidanceModalTemplate: React.FC<GuidanceModalTemplateProps> = ({
  selectedContent,
  onChange,
  modalWidth,
  heightWindow,
  indexModal,
  type = "talenta",
}) => {
  const { t } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const { isLocalTransfer } = useUploadReceiptScreenType();
  const tDecider = isLocalTransfer ? tLocalTransfer : t;

  const titleOption = guidanceModalType(type, tDecider);

  const { title, img, desctext } = selectedContent || {};

  return (
    <div
      className="d-flex"
      style={{
        borderBottom: `1px solid ${jackColors.greyE6}`,
        borderBottomWidth: 1,
        borderColor: jackColors.greyE6,
        flexDirection: "row",
      }}
    >
      <div
        style={{
          borderRight: `1px solid ${jackColors.greyE6}`,
          borderRightWidth: 1,
          borderColor: jackColors.greyE6,
          width: 0.353200883 * modalWidth,
        }}
      >
        {titleOption.map(({ title }, index) => {
          return (
            <div
              className="hover-600"
              style={{
                paddingLeft: 20,
                paddingTop: 20,
                paddingRight: 20,
                paddingBottom: 20,
                cursor: "pointer",
                backgroundColor:
                  indexModal === index ? `rgba(230, 230, 232, 0.5)` : "white",
                borderBottom: `1px solid ${jackColors.greyE6}`,
                alignItems: "center",
              }}
              key={index}
              onClick={() => onChange(index)}
            >
              <div className="d-flex justify-content-between">
                <GothamMedium
                  className="font14"
                  style={{
                    color: jackColors.neutral900,
                    textAlign: "left",
                  }}
                >
                  {title}
                </GothamMedium>
                <JackIcons
                  name="chevron-right"
                  fill="#343434"
                  style={{ width: 24, height: 24 }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          padding: 20,
          width: 0.64679911699 * modalWidth,
          height: 0.7 * heightWindow,
          overflow: "auto",
        }}
      >
        <div>
          <GothamMedium className="font20">{title}</GothamMedium>
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            {img && (
              <img
                src={img}
                height="100%"
                width="100%"
                alt="downloadtemplate"
              />
            )}
          </div>
          {desctext}
        </div>
      </div>
    </div>
  );
};

export default GuidanceModalTemplate;
