import JackIcons from "assets/jackIcons/typescript/parent";
import { CSSProperties } from "react";
import { colors, jackColors } from "../../assets/colors";
import { BannerProps } from "./Banner.types";
import { GothamMedium, GothamRegular } from "./Text";
import lightBulbOn from "../../public/images/LightBulbOn.svg";

export const Banner = ({
  title = "",
  msg = "",
  type = "information",
  button = null,
  titleStyle,
  msgStyle: msgStyleProps = {},
  className: classNameProps = "",
  bannerStyle: bannerStyleProps = {},
  iconStyle: iconStyleProps = {},
  textContainerStyle: textContainerStyleProps = {},
  textIconStyleProps = {},
  noIcon = false,
  style = {},
  customIcon,
  noTopBorderRadius = false,
  noBorder = false,
}: BannerProps) => {
  // Determine the icon based on the type or customIcon prop
  const iconDecider = (): { name: string; fill: string } | undefined => {
    if (customIcon) return customIcon;
    switch (type) {
      case "information":
        return { name: "info", fill: colors.neutral700 };
      case "warning":
        return { name: "alert-circle", fill: colors.yellowFC };
      case "error":
        return { name: "alert-triangle", fill: colors.redE7 };
      case "info-alternate":
        return { name: "Lightbulb", fill: colors.grey90 };
      case "success":
        return { name: "CheckmarkCircle2", fill: "white" };
      case "custom-icon":
        return { name: "", fill: "" };
      case "help-center":
        return { name: "HelpCenterNew", fill: jackColors.black34 };
      default:
        return undefined;
    }
  };

  const iconProps = iconDecider();

  // Determine the style based on the type prop
  const typeStyleDecider = (): CSSProperties => {
    switch (type) {
      case "information":
      case "info-alternate":
        return {
          backgroundColor: colors.neutral400,
          border: noBorder ? "" : `1px solid ${colors.neutral600}`,
        };
      case "warning":
        return {
          backgroundColor: colors.yellowF7,
          border: `1px solid ${colors.yellowFC}`,
        };
      case "error":
        return {
          backgroundColor: colors.redFF,
          border: `1px solid ${colors.redE7}`,
        };
      case "success":
        return {
          backgroundColor: colors.greenE5,
          border: `1px solid ${colors.green06}`,
        };
      case "custom-icon":
        return {
          backgroundColor: "rgba(241, 241, 241, 0.5)",
          border: "",
        };
      case "help-center":
        return {
          backgroundColor: colors.neutral400,
          border: noBorder ? "" : `1px solid ${colors.neutral600}`,
        };
      default:
        return {};
    }
  };

  const typeStyle = typeStyleDecider();

  const bannerStyle: CSSProperties = {
    display: "flex",
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    justifyContent: "space-between",
    borderTopLeftRadius: noTopBorderRadius ? "0" : "4px", // Adjust the values as needed
    borderTopRightRadius: noTopBorderRadius ? "0" : "4px",
    ...typeStyle,
    ...bannerStyleProps,
    ...style,
  };

  const textIconStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    width: "100%",
    ...textIconStyleProps,
  };

  const iconStyle: CSSProperties = {
    width: "20px",
    height: "20px", // Corrected typo from "heigth" to "height"
    ...iconStyleProps,
  };

  const textContainerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "100%",
    ...textContainerStyleProps,
  };

  const headingStyle: CSSProperties = {
    color: colors.neutral900,
    fontSize: "12px",
    lineHeight: "16px",
    ...titleStyle,
  };

  const msgStyle: CSSProperties = {
    color: colors.neutral800,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    ...msgStyleProps,
  };

  return (
    <div className={classNameProps} style={bannerStyle}>
      <div style={textIconStyle}>
        {!noIcon &&
          iconProps &&
          (type == "custom-icon" ? (
            <img src={lightBulbOn} alt="icon" style={{ width: 20 }} />
          ) : (
            <JackIcons
              name={iconProps.name}
              fill={iconProps.fill}
              style={iconStyle}
            />
          ))}
        <div style={textContainerStyle}>
          {title && <GothamMedium style={headingStyle}>{title}</GothamMedium>}
          <GothamRegular style={msgStyle}>{msg}</GothamRegular>
        </div>
      </div>
      {button && button}
    </div>
  );
};
