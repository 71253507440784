import talenta from "../../../public/images/talenta.png";
import gadjian from "../../../public/images/gadjian.png";
import bca from "../../../public/images/BCA.png";
import oy from "../../../public/images/oy.png";
import xendit from "../../../public/images/xendit.png";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { gothamizedText } from "pageComponents/draftComponents/tools/helpers";

export const TemplatePayrollArr = [
  { image: talenta, title: "Talenta", value: "talenta" },
  // { image: gadjian, title: "Gadjian", value: "gadjian" },
  // { image: bca, title: "BCA Multi Auto Payment", value: "bca" },
  // { image: oy, title: "OY", value: "oy" },
  // { image: xendit, title: "Xendit", value: "xendit" },
];

export const invalidValueBodyFormat = (row) => {
  const { invalidValue, row: rowIndex } = row || {};

  const rowInvalidIndex = gothamizedText(rowIndex);
  const invalidValueBody = gothamizedText(invalidValue);

  return [rowInvalidIndex, invalidValueBody];
};

export const useInvalidValueCSV = (data) => {
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");
  const tbodyRef = useRef(null);

  const tableTotalWidth = 1136;
  const tableWidth = (number) => `${(number / tableTotalWidth) * 100}%`;

  const headerArr = [
    {
      label: tLocalTransfer("Row"),
      width: tableWidth(104),
    },
    {
      label: tLocalTransfer("Incorrect Field / Column"),
      width: tableWidth(443),
    },
  ];

  let bodyTable = data.map((item) => {
    return {
      rawData: [item],
      tableRow: item ? invalidValueBodyFormat(item) : [],
    };
  });

  return { headerArr, tbodyRef, bodyTable };
};
