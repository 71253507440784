import { useForm } from "@tools";
import { ButtonJack } from "components/ButtonsJack/parent";
import JackIcons from "assets/jackIcons/typescript/parent";
import { useModalHook } from "components/Modals";
import { GothamMedium, GothamRegular } from "components/typescript/Text";
import SimpleCenteredModal from "modals/SimpleCenteredModal/typescript";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MIXPANELS from "universalFunctions/mixpanels";
import { useCreateRecipients, useValidate } from "../hooks";
import { FormContent } from "./components";
import { AddRecipientModalProps, RecipientType } from "./index.types";
import styles from "./styles.module.css";

const AddRecipientModal = ({
  toggle: toggleRaw = () => {},
  isOpen = false,
  nextAction,
}: AddRecipientModalProps) => {
  const { t } = useTranslation("local-transfer/revamp");
  const [recipient, setRecipient] = useState<RecipientType | undefined>(
    undefined
  );
  const { query, push } = useRouter();
  const { type: _, ...rest } = query;
  const toggle = () => {
    toggleRaw();
    reset();
    handleClearForm();
  };

  const formMethods = useForm();
  const useFormObj: UseFormReturn<FieldValues> = formMethods;

  const {
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setError,
  } = useFormObj;

  const bank = watch("bank_name");
  const accountNumber = watch("account_number");

  const { create, loading: loadingCreateRecipient } = useCreateRecipients({
    afterSuccess: () => {
      const { is_new_recipient } = recipient ?? {};
      if (typeof nextAction == "function") nextAction(!!is_new_recipient);
      return toggle();
    },
  });

  const [limit, setLimit] = useState<string>("");
  const {
    isOpen: isOpenLimitValidationWarning,
    toggle: toggleLimitValidationWarning,
  } = useModalHook();

  const { validate, loading: loadingValidate } = useValidate({
    afterSuccess: (data) => {
      if (data?.data) {
        const { name: nameRaw, registered_name, is_valid } = data.data;
        const name = nameRaw || registered_name;
        const recipient = data.data;
        if (!is_valid) {
          setError("bank_name", {
            type: "manual",
          });
          setError("account_number", {
            type: "manual",
          });
        }
        if (!!name) {
          setRecipient(recipient);
        }
      }
    },
    triggerFromOutside: setLimit,
  });

  useEffect(() => {
    if (!limit) return;
    toggleLimitValidationWarning();
  }, [limit]);

  useEffect(() => {
    if (!isOpenLimitValidationWarning) setLimit("");
  }, [isOpenLimitValidationWarning]);

  const handleCreateRecipient = async (data: FieldValues) => {
    const { account_number, bank_name } = data;
    const { id } = bank_name;

    const payload = {
      recipients: [
        {
          payer_id: id,
          account_number,
        },
      ],
    };
    await create(payload);
  };

  const handleValidate = async (data: FieldValues) => {
    const { account_number, bank_name } = data;
    const { id: payer_id } = bank_name;

    const payload = {
      payer_id,
      account_number,
    };
    await validate(payload);
  };

  const handleClearForm = () => {
    setRecipient(undefined);
  };

  const disableButton = !(bank && accountNumber);
  const isRecipientSet = !!recipient;

  const nextButtonText = isRecipientSet ? "Continue" : "Validate";
  const loading = loadingCreateRecipient || loadingValidate;

  const nextActionFunctionDecider = () => {
    if (isRecipientSet) {
      const { is_new_recipient } = recipient ?? {};
      if (!is_new_recipient) {
        return toggle;
      }
      return handleSubmit((val) => {
        handleCreateRecipient(val);
        MIXPANELS.LOCAL_TRANSFER.LOCAL_SINGLE_ADD_LIST_CONTINUE();
      });
    }
    return handleSubmit((val) => {
      MIXPANELS.LOCAL_TRANSFER.LOCAL_SINGLE_ADD_LIST_VALIDATE();
      handleValidate(val);
    });
  };
  const nextActionFunc = nextActionFunctionDecider();

  useEffect(() => {
    if (!isOpen) {
      push({ query: rest });
    }
  }, [isOpen]);

  return (
    <Fragment>
      <SimpleCenteredModal
        modalClassName={styles["modal"]}
        leftHeaderComponent={
          <GothamMedium className="font20">
            {t("Add new recipient")}
          </GothamMedium>
        }
        customStyle={{
          zIndex: 1000,
        }}
        isOpen={isOpen}
        toggle={toggle}
        onClick={() => {}}
        customButtons={
          <ButtonJack
            disabled={disableButton}
            isLoading={loading}
            style={{ width: "100%" }}
            children={t(nextButtonText)}
            onClick={nextActionFunc}
          />
        }
      >
        <div style={{ padding: 20 }}>
          <FormContent
            handleClearForm={handleClearForm}
            handleSubmit={handleSubmit(handleValidate)}
            useFormObj={useFormObj}
            errors={errors}
            recipient={recipient}
            isModal
          />
        </div>
      </SimpleCenteredModal>
      <WarningModal
        isOpen={isOpenLimitValidationWarning}
        toggle={toggleLimitValidationWarning}
        limit={limit}
      />
    </Fragment>
  );
};

export default AddRecipientModal;

export const WarningModal = ({
  isOpen,
  toggle,
  limit,
}: {
  isOpen: boolean;
  toggle: () => void;
  limit: string;
}) => {
  const { t } = useTranslation("local-transfer/revamp");
  return (
    <SimpleCenteredModal
      woLineDividers
      isOpen={isOpen}
      toggle={toggle}
      modalClassName={styles["warning-modal"]}
      children={
        <div
          style={{
            padding: 20,
            paddingTop: 0,
            paddingBottom: 12,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <JackIcons name="AddRecipientLimit" style={{ marginTop: "-40px" }} />
          <GothamMedium className="font20 text-black34 text-center" woFontColor>
            {limit === "daily_limit"
              ? t("Daily bank validation limit reached")
              : t("Please wait before validating again")}
          </GothamMedium>
          <GothamRegular className="text-grey6c text-center" woFontColor>
            {limit === "daily_limit"
              ? t(
                  "Please try again tomorrow or use the batch transfer method (Excel or empty table) for multiple recipients."
                )
              : t(
                  "You have attempted 3 account validations in the past minute. Please wait a moment before trying again."
                )}
          </GothamRegular>
        </div>
      }
      buttonText={t("Got it")}
      onClick={toggle}
    />
  );
};
