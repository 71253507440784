import { windowDimension } from "@tools";
import { useModalWidth } from "components/Modals";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import { contentModalCustomPayroll, TextArr } from "./constant";
import { GothamMedium, GothamRegular } from "components/typescript/Text";
import JackIcons from "assets/jackIcons/typescript/parent";
import { jackColors } from "assets/colors";
import {
  DataContentType,
  GuideTemplateModalJackProps,
} from "types/modals/guide_template_jack/type";
import GuidanceModalTemplate from "./GuidanceModalTemplate";
import { useUploadReceiptScreenType } from "modals/smartUploadReceiptModal/logics";
import { ButtonJack } from "components/ButtonsJack/parent";

export const dataContent = (t: (item: string) => string): DataContentType[] => {
  return [
    {
      key: "recipient_name",
      content: [
        {
          text: (
            <GothamMedium className="font12">
              {t(`Recipient Name`)}
            </GothamMedium>
          ),
          optionalText: (
            <GothamRegular
              className="font10"
              style={{ color: jackColors.grey90 }}
            >
              {t(`(*Required)`)}
            </GothamRegular>
          ),
        },
        {
          text: (
            <GothamRegular className="font12">
              <Trans
                i18nKey={t("dynamic.style_text_18")}
                components={[
                  <span
                    style={{
                      fontFamily: "GothamMedium",
                      color: jackColors.black34,
                    }}
                  />,
                ]}
              />
            </GothamRegular>
          ),
        },
        {
          text: (
            <GothamRegular className="font12">
              {t(
                `Use alphanumeric. Must include letters, not just numbers & without special characters.`
              )}
            </GothamRegular>
          ),
        },
      ],
    },
    {
      key: "transfer_destination",
      content: [
        {
          text: (
            <GothamMedium className="font12">
              {t(`Transfer Destination`)}
            </GothamMedium>
          ),
          optionalText: (
            <GothamRegular
              className="font10"
              style={{ color: jackColors.grey90 }}
            >
              {t(`(*Required)`)}
            </GothamRegular>
          ),
        },
        {
          text: (
            <GothamRegular className="font12">
              {t(`The bank name doesn’t match`)}
            </GothamRegular>
          ),
        },
        {
          text: (
            <GothamRegular className="font12">
              <Trans
                i18nKey={t("dynamic.style_text_17")}
                components={[
                  <span
                    className="font12"
                    style={{
                      fontFamily: "GothamMedium",
                      color: jackColors.black34,
                    }}
                  />,
                ]}
              />
            </GothamRegular>
          ),
        },
      ],
    },
    {
      key: "account_number",
      content: [
        {
          text: (
            <GothamMedium className="font12">
              {t(`Account Number `)}
            </GothamMedium>
          ),
          optionalText: (
            <GothamRegular
              className="font10"
              style={{ color: jackColors.grey90 }}
            >
              {t(`(*Required)`)}
            </GothamRegular>
          ),
        },
        {
          isBulletNumbering: true,
          text: [
            <TextArr
              arr={[t(`Use letters/special characters`)]}
              customStyles={{ className: `font12` }}
            />,
            <TextArr
              customStyles={{ className: `font12` }}
              arr={[
                t(
                  `The recipient's account number don't meet the bank's requirements`
                ),
              ]}
            />,
          ],
        },
        {
          isBulletNumbering: true,
          text: [
            <TextArr
              arr={[t(`Only use numbers (0-9)`)]}
              customStyles={{ className: `font12` }}
            />,
            <TextArr
              customStyles={{ className: `font12` }}
              arr={[
                t(
                  `Minimum 10 digits, including 0 at the beginning of the account number (if any)`
                ),
              ]}
            />,
          ],
        },
      ],
    },
    {
      key: "amount",
      content: [
        {
          text: <GothamMedium className="font12">{t(`Amount`)}</GothamMedium>,
          optionalText: (
            <GothamRegular
              className="font10"
              style={{ color: jackColors.grey90 }}
            >
              {t(`(*Required)`)}
            </GothamRegular>
          ),
        },
        {
          text: (
            <GothamRegular className="font12">
              <Trans
                i18nKey={t("dynamic.style_text_19")}
                components={[
                  <span
                    style={{
                      fontFamily: "GothamMedium",
                      color: jackColors.black34,
                    }}
                  />,
                ]}
              />
            </GothamRegular>
          ),
        },
        {
          text: (
            <GothamRegular className="font12">
              {t(`Fill the column with numbers only (0-9), without (,) or (.)`)}
            </GothamRegular>
          ),
        },
      ],
    },
    {
      key: "note",
      content: [
        {
          text: <GothamMedium className="font12">{t(`Note`)}</GothamMedium>,
          optionalText: (
            <GothamRegular
              className="font10"
              style={{ color: jackColors.grey90 }}
            >
              {t(`(Optional)`)}
            </GothamRegular>
          ),
        },
        {
          text: (
            <GothamRegular className="font12">
              {t(`The note is too long`)}
            </GothamRegular>
          ),
        },
        {
          isBulletNumbering: true,
          text: [
            <TextArr
              customStyles={{ className: `font12` }}
              arr={[
                t(
                  `Keep it short, as some transfer services limit the number of characters.`
                ),
              ]}
            />,
            <TextArr
              customStyles={{ className: `font12` }}
              arr={[
                t(
                  `Alphanumeric is allowed, with only (-) and (/) as acceptable special characters`
                ),
              ]}
            />,
          ],
        },
      ],
    },
    {
      key: "email",
      content: [
        {
          text: <GothamMedium className="font12">{t(`Email`)}</GothamMedium>,
          optionalText: (
            <GothamRegular
              className="font10"
              style={{ color: jackColors.grey90 }}
            >
              {t(`(Optional)`)}
            </GothamRegular>
          ),
        },
        {
          isBulletNumbering: true,
          text: [
            <TextArr
              customStyles={{ className: `font12` }}
              arr={[t(`Incorrect email format`)]}
            />,
            <TextArr
              customStyles={{ className: `font12` }}
              arr={[t(`The column is filled, but the email is invalid`)]}
            />,
          ],
        },
        {
          isBulletNumbering: true,
          text: [
            <GothamRegular className="font12">
              <Trans
                i18nKey={t("dynamic.style_text_20")}
                components={[
                  <span
                    style={{
                      fontFamily: "GothamMedium",
                      color: jackColors.redDC,
                    }}
                  />,
                ]}
              />
            </GothamRegular>,
            <TextArr
              customStyles={{ className: `font12` }}
              arr={[t(`Make sure the email is valid`)]}
            />,
          ],
        },
      ],
    },
  ];
};

const GuideTemplateModalJack = ({
  isOpen,
  toggle,
  title = "Upload Guide",
  buttonRightText = "Got it!",
  buttonLeftText = "Close",
  type = "modal_csv",
}: GuideTemplateModalJackProps) => {
  const [indexModal, setIndexModal] = useState(0);
  const modalWidth = useModalWidth(906);
  const { height: heightWindow } = windowDimension();
  const { isPayroll } = useUploadReceiptScreenType();
  const { t } = useTranslation("local-transfer/local-transfer");
  const { t: tPayroll } = useTranslation("payroll/create");
  const tDecider = isPayroll ? tPayroll : t;
  const data: DataContentType[] = dataContent(t);

  const { locale } = useRouter();

  const isIndo = locale === "id";

  const onChange = (index: number): void => {
    setIndexModal(index);
  };

  const selectedContent = contentModalCustomPayroll({
    onChange,
    t: tDecider,
    isIndo,
    type: type,
    data,
  });
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName={"modal-transfez"}
    >
      <div
        style={{
          position: "relative",
          width: modalWidth,
          borderRadius: 8,
          flexDirection: "column",
          justifyContent: "start",
        }}
        className="d-flex"
      >
        <div
          style={{
            padding: 20,
            borderBottom: `1px solid ${jackColors.greyE6}`,
            borderBottomWidth: 1,
            borderColor: jackColors.greyE6,
            alignItems: "center",
          }}
        >
          <GothamMedium className="font20">{t(title)}</GothamMedium>
          <div
            className="hover-600"
            style={{
              width: 40,
              height: 40,
              border: "1px solid #E6E6E8",
              borderRadius: 4,
              display: "grid",
              placeItems: "center",
              position: "absolute",
              cursor: "pointer",
              right: 20,
              top: 15,
            }}
            onClick={toggle}
          >
            <JackIcons
              name="close_Outline"
              fill="#343434"
              style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
        <GuidanceModalTemplate
          selectedContent={selectedContent[indexModal]}
          onChange={onChange}
          modalWidth={modalWidth}
          heightWindow={heightWindow}
          indexModal={indexModal}
          type={type}
          data={data}
        />
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%", gap: 16, padding: 20 }}
        >
          <ButtonJack
            style={{ width: "100%" }}
            type="outline"
            onClick={() => toggle()}
          >
            {t(buttonLeftText)}
          </ButtonJack>
          <ButtonJack style={{ width: "100%" }} onClick={() => toggle()}>
            {t(buttonRightText)}
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};

export default GuideTemplateModalJack;
