import { TextField } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Controller } from "react-hook-form";
import { colors, jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { useLayout } from "../../../contexts/Layout/parent";
import { useModalHook } from "../../Modals";
import { GothamMedium, GothamRegular } from "../../Text";
import { useDebounce, windowDimension } from "../../tools";
import { DateContainer, DateContainerMonthYearDropdown } from "./container";
import { classNameHiders, classNamePickAndEditStyle, safeDate } from "./logics";
import id from "date-fns/locale/id";
import en from "date-fns/locale/en-GB";
import { useLanguage } from "public/locales/translationFunctions";
import styled from "styled-components";

registerLocale("id", id);
registerLocale("en", en);

const StyledDatePickerWrapper = styled.div`
  width: 100%; /* Full width */
  .react-datepicker-wrapper {
    width: 100%; /* Ensure wrapper stretches */
  }

  .react-datepicker__input-container {
    width: 100%; /* Ensure input container stretches */
  }
`;

export const DateInputJackState = ({
  value = null,
  onChange = () => {},
  label,
  placeholder = "",
  maxDate,
  minDate,
  style,
  disabled,
  required,
  dateFormat = "d MMM yyyy",
  isError = false,
  isModal,
  propsDatePicker: propsDatePickerProps,
  isShowDatePicker,
  isClearable = false,
  forceDropdownTop = false,
  customFieldStyle = "",
  customLabelStyle = "",
  noLabel = false,
  type = "",
  offset = 0,
  isLabelBold,
  ...props
}) => {
  const { isBahasa } = useLanguage();
  const propsDatePicker = {
    ...propsDatePickerProps,
    ...(isShowDatePicker
      ? {
          showMonthDropdown: true,
          showYearDropdown: true,
          yearDropdownItemNumber: 100,
          scrollableYearDropdown: true,
        }
      : {}),
  };

  const { scrollTop } = useLayout();

  const { isOpen, close, open } = useModalHook();
  const debouncedIsOpen = useDebounce(isOpen, 10);

  const ref = useRef();

  useEffect(() => {
    if (!ref?.current) return;

    close();
    ref.current.setOpen(false);
  }, [scrollTop]);

  useEffect(() => {
    if (!isOpen) return;
    classNamePickAndEditStyle("react-datepicker-popper", {
      display: "none",
    });
  }, [isOpen]);

  const [divRef, setDivRef] = useState({});

  const { height } = windowDimension();

  const bottom = divRef?.getBoundingClientRect
    ? divRef?.getBoundingClientRect()?.bottom
    : 0;

  const isDropdownTop = bottom + 320 - 64 + offset > height || forceDropdownTop;

  useEffect(() => {
    if (!debouncedIsOpen) return;
    const leftArrow =
      "react-datepicker__navigation react-datepicker__navigation--previous";
    const rightArrow =
      "react-datepicker__navigation react-datepicker__navigation--next";
    const date = "react-datepicker__current-month";
    const array = [leftArrow, rightArrow, date];

    classNameHiders(array);

    classNamePickAndEditStyle("react-datepicker", {
      border: "0px solid black",
      boxShadow:
        "0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16)",
      borderRadius: "8px",
    });

    classNamePickAndEditStyle("react-datepicker-popper", {
      position: "fixed",
      inset: "",
      transform: "",
      display: "flex",
      "margin-top": isDropdownTop ? "-120px" : "0px",
      zIndex: 1000000000,
    });

    !isModal &&
      classNamePickAndEditStyle("react-datepicker-popper", {
        position: "absolute",
        transition: "all 0.2s linear",
      });
  }, [debouncedIsOpen]);

  const isCustom = Boolean(type);
  const showPlaceHolder = isCustom
    ? !value
    : !isOpen && !value && !!placeholder;

  const borderColorDecider = () => {
    if (isError) return jackColors.redDC;

    if (isCustom) {
      if (value) return jackColors.greyE6;
      if (!isOpen) return jackColors.yellowFC;
    }

    return jackColors.black34;
  };

  const backGroundColorDecider = () => {
    if (isCustom && !value) return "rgba(255, 246, 230, 1)";
    if (isCustom) return "white";
    if (disabled) return "rgba(0,0,0,.05)";
    return "transparent";
  };
  const borderColor = borderColorDecider();
  const backgroundColor = backGroundColorDecider();
  return (
    <div
      style={{
        marginBottom: 12,
        position: "relative",
        ...style,
      }}
      ref={(e) => setDivRef(e)}
    >
      {showPlaceHolder && (
        <GothamRegular
          style={{
            position: "absolute",
            bottom: 8,
            left: isCustom ? 36 : 12,
            color: isCustom ? jackColors.black34 : jackColors.greyBB,
            zIndex: isCustom && 2,
          }}
        >
          {placeholder}
        </GothamRegular>
      )}
      {!noLabel &&
        (isLabelBold ? (
          <GothamMedium
            className="font12 mb-2"
            style={{ color: isError && jackColors.redDC, ...customLabelStyle }}
          >
            {label}
            {required && <span style={{ color: "red" }}>*</span>}
          </GothamMedium>
        ) : (
          <GothamRegular
            className="font12 mb-2"
            style={{ color: isError && jackColors.redDC, ...customLabelStyle }}
          >
            {label}
            {required && <span style={{ color: "red" }}>*</span>}
          </GothamRegular>
        ))}
      <StyledDatePickerWrapper>
        <DatePicker
          locale={isBahasa ? "id" : "en"}
          ref={ref}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          dateFormat={dateFormat}
          onCalendarOpen={open}
          onCalendarClose={close}
          onChange={(val) => {
            if (!safeDate(val)) return;
            onChange(val);
          }}
          calendarContainer={
            isShowDatePicker ? DateContainerMonthYearDropdown : DateContainer
          }
          selected={value}
          customInput={
            <TextField
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <div className="d-flex align-items-center ml-1">
                    {isClearable && value ? (
                      <JackIcons
                        name="close_outline"
                        fill={jackColors.greyBB}
                        style={{ height: 20, width: 20, cursor: "pointer" }}
                        onClick={() => onChange("")}
                      />
                    ) : (
                      <JackIcons
                        name={
                          Boolean(props?.startAdornment)
                            ? "chevron_down"
                            : "calendar_today"
                        }
                        fill={jackColors.greyBB}
                        style={{ height: 20, width: 20 }}
                      />
                    )}
                  </div>
                ),
                startAdornment: isCustom && props?.startAdornment,
                style: {
                  fontFamily: "GothamBook",
                  fontSize: 14,
                  marginTop: -4,
                  color: isCustom && "#6087e7",
                },
              }}
              variant="standard"
              style={{
                height: 40,
                borderRadius: 4,
                width: "100%",
                padding: 12,
                paddingTop: 10,
                paddingBottom: 10,
                border: `1px solid ${borderColor}`,
                transition: "all 0.3s ease",
                backgroundColor: backgroundColor,
                ...customFieldStyle,
              }}
              className={isOpen || isError || isCustom ? "" : "textfield"}
              {...props}
            />
          }
          {...propsDatePicker}
        />
      </StyledDatePickerWrapper>
    </div>
  );
};

const errorStyle = { color: colors.redE7, fontSize: "10px" };

export const DateInputJackUseForm = ({
  useFormObj: { control, errors },
  name,
  defaultValue = "",
  label,
  containerStyle,
  type = "",
  ...props
}) => {
  const errorMsg = errors[name]?.message;
  const isError = Boolean(errorMsg);
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => {
        const { value, onChange } = field ?? {};
        return (
          <div style={{ marginBottom: 32, ...containerStyle }}>
            <DateInputJackState
              value={value}
              onChange={onChange}
              label={label}
              style={{ marginBottom: 0 }}
              isError={isError}
              type={type}
              {...props}
            />
            {isError ? (
              <GothamRegular style={errorStyle}>{errorMsg}</GothamRegular>
            ) : null}
          </div>
        );
      }}
    />
  );
};
