import { colors } from "assets/colors";
import React, { ReactNode, CSSProperties, useEffect, useState } from "react";

interface TransitionTrProps extends React.HTMLAttributes<HTMLTableRowElement> {
  counter?: number;
  perPage?: number;
  style?: CSSProperties;
  children?: ReactNode;
  isQuick?: boolean;
  isSelected?: boolean;
}

const useDefaultHooks = (
  counter: number,
  perPage: number,
  isQuick: boolean
): boolean => {
  const newCounter = counter % perPage;
  const timer = isQuick ? 25 * newCounter : 100 * newCounter;

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const showTimeout = setTimeout(() => setShow(true), timer);

    return () => clearTimeout(showTimeout);
  }, [timer]);

  return show;
};

export default useDefaultHooks;

export const TransitionTr: React.FC<TransitionTrProps> = ({
  counter = 0,
  perPage = 10,
  style,
  children,
  isQuick = false,
  isSelected,
  ...props
}) => {
  const show = useDefaultHooks(counter, perPage, isQuick);

  const hideBorder: CSSProperties = show ? {} : { borderWidth: 0 };

  if (isQuick) {
    return (
      <tr
        style={{
          opacity: 1,
          transition: "all 0.4s linear",
          backgroundColor: isSelected ? colors.neutral500 : undefined,
          ...hideBorder,
          ...style,
        }}
        {...props}
      >
        {children}
      </tr>
    );
  }

  return (
    <tr
      style={{
        opacity: show ? 1 : 0,
        transition: "all 0.4s linear",
        backgroundColor: isSelected ? colors.neutral500 : undefined,
        ...hideBorder,
        ...style,
      }}
      {...props}
    >
      {children}
    </tr>
  );
};
