import { windowDimension } from "@tools";
import { jackColors } from "assets/colors";
import JackIcons from "assets/jackIcons/typescript/parent";
import { ButtonJack } from "components/ButtonsJack/parent";
import { useModalWidth } from "components/Modals";
import { TableJackSimple } from "components/Table/parent";
import { Banner } from "components/typescript/Banner";
import { GothamMedium, GothamRegular } from "components/typescript/Text";
import { SpanMedium } from "modals/smartActivityModal/crossBorderSingleModal/transferServiceModal/components";
import { useRouter } from "next/router";
import { useJackTransferStoredData } from "pageComponents/jackTransferComponents/hooks";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import imgBack from "../../../assets/images/back-previous-page.svg";
import checkExcelImg from "../../../assets/images/check-excel.svg";
import failCreateImg from "../../../assets/images/fail-create-from-bg.svg";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { apiBusiness } from "../../../tools/api";
import { useInvalidValueCSV } from "./constant";
import ViewportList from "react-viewport-list";
import { TransitionTr } from "components/Transition";

export const ModalConfirmationCloseLocal = ({
  toggle,
  isOpen,
  product = "local",
  isAfterDraft = false,
  customText,
  batchId = "",
}) => {
  const { query, push } = useRouter();
  const { removeJackTransferData } = useJackTransferStoredData();
  const { t } = useTranslation("international/create");
  const { t: tPayroll } = useTranslation("payroll/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const isResubmit = query?.action === "resubmit";

  const isLocal = product === "local";
  const isPayroll = product === "payroll";
  const isInternational = product === "international";

  const textDecider = () => {
    if (customText) return customText;
    if (isAfterDraft) {
      return (
        <>
          <Trans
            i18nKey={tPayroll("dynamic.style_text_4")}
            components={[<span style={{ fontFamily: "GothamMedium" }} />]}
          />
        </>
      );
    }
    return isLocal
      ? tLocalTransfer(
          "Going back means starting over and losing all progress made."
        )
      : t("Going back means starting over and losing all progress made.");
  };

  const titleDecider = () => {
    if (isLocal) return tLocalTransfer("Close Local Transfer?");
    if (isPayroll) return tPayroll("Close Payroll?");
    return t("Close International Transfer?");
  };

  const NavigationPush = () => {
    if (isResubmit) return "/dashboard";
    if (isPayroll || isLocal) {
      if (isAfterDraft)
        return `/draft/?menu=${isPayroll ? "payroll" : "local_transfer"}`;
      return "/dashboard";
    }
    return "/dashboard";
  };

  const nav = NavigationPush();
  const title = titleDecider();
  const text = textDecider();

  const handleClick = async () => {
    if (isResubmit) {
      if (isLocal) {
        await apiBusiness.delete(`/local_transaction_batches/${batchId}`);
      }

      if (isPayroll) {
        await apiBusiness.delete(`/payrolls/${batchId}`);
      }
    }
    removeJackTransferData();
    push(nav);
  };

  return (
    <ConfirmationModalJack
      onClick={handleClick}
      modal={isOpen}
      toggle={toggle}
      title={title}
      text={text}
      buttonTextRight={t("Close")}
      buttonTextLeft={t("Cancel")}
      img={imgBack}
      customContentClassName="modal-border-radius4"
    />
  );
};

export const ModalFailExcel = ({
  isOpen,
  toggle,
  text = "There are still some incorrect fields that don't meet the requirements. Please recheck and try uploading it again. ",
  img = checkExcelImg,
  isOnlyOneButton = true,
  buttonTextLeft = "Got it",
  buttonTextRight,
  onClickRight,
}) => {
  const { t } = useTranslation("payroll/create");
  return (
    <ConfirmationModalJack
      title={
        <span style={{ fontSize: 20, lineHeight: "24px" }}>
          {t("Let’s double-check your CSV file")}
        </span>
      }
      modal={isOpen}
      img={img}
      toggle={toggle}
      onClick={toggle}
      isOnlyOneButton={isOnlyOneButton}
      buttonTextRight={buttonTextRight}
      buttonTextLeft={buttonTextLeft}
      text={text}
      customContentClassName="modal-border-radius4"
      onClickRight={onClickRight}
    />
  );
};
export const ModalFailCreateBG = ({
  isOpen,
  toggle,
  isPayroll = false,
  backToFirst,
  customText = false,
}) => {
  const { push } = useRouter();
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const onClickLeft = () => {
    if (isPayroll) return push("/draft/?menu=payroll");
    return push("/draft/?menu=local_transfer");
  };

  return (
    <ConfirmationModalJack
      title={
        <span style={{ fontSize: 20, lineHeight: "24px" }}>
          {tLocalTransfer("Sorry, there’s a little problem on our system")}
        </span>
      }
      modal={isOpen}
      img={failCreateImg}
      toggle={toggle}
      buttonTextRight={tLocalTransfer("Re-upload File")}
      buttonTextLeft={tLocalTransfer("Close")}
      onClick={backToFirst}
      onClickLeft={onClickLeft}
      text={
        <Trans
          i18nKey={tLocalTransfer("dynamic.style_text_9")}
          components={{ SpanMedium: <SpanMedium /> }}
        />
      }
      customContentClassName="modal-border-radius4"
    />
  );
};

export const ModalFailPassChecker = ({
  isOpen,
  toggle,
  title = "Upload Guide",
  buttonRightText = "Got it!",
  buttonLeftText = "Close",
  isShowAlert = false,
  onClickRight = () => {},
  data,
}) => {
  const [indexModal, setIndexModal] = useState(0);
  const modalWidth = useModalWidth(587);
  const { t } = useTranslation("local-transfer/local-transfer");

  const onChange = (index) => {
    setIndexModal(index);
  };
  const { headerArr, bodyTable, tbodyRef } = useInvalidValueCSV(data);
  // const selectedContent = contentModalCustomPayroll(onChange, t, isIndo);

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName={"modal-transfez"}
    >
      <div
        style={{
          position: "relative",
          width: modalWidth,
          borderRadius: 8,
          flexDirection: "column",
          justifyContent: "start",
        }}
        className="d-flex"
      >
        <div
          style={{
            padding: 20,
            borderBottom: `1px solid ${jackColors.greyE6}`,
            borderBottomWidth: 1,
            borderColor: jackColors.greyE6,
            alignItems: "center",
          }}
        >
          <GothamMedium className="font20">{t(title)}</GothamMedium>
          <div
            className="hover-600"
            style={{
              width: 40,
              height: 40,
              border: "1px solid #E6E6E8",
              borderRadius: 4,
              display: "grid",
              placeItems: "center",
              position: "absolute",
              cursor: "pointer",
              right: 20,
              top: 15,
            }}
            onClick={toggle}
          >
            <JackIcons
              name="close_Outline"
              fill="#343434"
              style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
        <div style={{ margin: "0px 20px" }}>
          {/* <TemplateModalTalenta
          selectedContent={selectedContent[indexModal]}
          onChange={onChange}
          modalWidth={modalWidth}
          heightWindow={heightWindow}
          indexModal={indexModal}
          /> */}
          <div style={{ marginTop: 20, marginBottom: 24 }}>
            <TableJackSimple
              headerTable={headerArr}
              tbodyRef={tbodyRef}
              bodyComponent={
                <>
                  <ViewportList viewportRef={tbodyRef} items={bodyTable}>
                    {({ rawData, tableRow }, index) => {
                      return (
                        <TransitionTr
                          isQuick
                          counter={index}
                          perPage={20}
                          key={index}
                          // onClick={() => setSelectedItem(rawData)}
                          // isSelected={isSelected}
                          // style={
                          //   index == bodyTable.length - 1 ? styles.lastRow : ""
                          // }
                        >
                          {tableRow.map((item, index) => {
                            return (
                              <td key={index}>
                                <GothamRegular
                                  style={{
                                    color: jackColors.black34,
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                  className={"font14"}
                                >
                                  {item}
                                </GothamRegular>
                              </td>
                            );
                          })}
                        </TransitionTr>
                      );
                    }}
                  </ViewportList>
                </>
              }
              tableStyle={{ borderWidth: 1 }}
              thStyle={{
                backgroundColor: jackColors.greyE6,
                justifyContent: "center",
                alignItems: "center",
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
              }}
              labelStyle={{
                textAlign: "center",
                fontFamily: "GothamMedium",
                color: jackColors.black34,
              }}
              noHeaderPadding
              classNameLabel="font14"
            />
          </div>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{
            width: "100%",
            gap: 16,
            padding: 20,
            marginTop: 20,
            borderTop: `1px solid ${jackColors.greyE6}`,
          }}
        >
          <ButtonJack
            style={{ width: "100%" }}
            type="outline"
            onClick={() => toggle()}
          >
            {t(buttonLeftText)}
          </ButtonJack>
          <ButtonJack style={{ width: "100%" }} onClick={() => onClickRight()}>
            {t(buttonRightText)}
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};
